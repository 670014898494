import axios from "axios";
import { aviationAppEnv } from "../model/config/IConfigSetting";

// export const httpAviationApimDev= axios.create({
//   baseURL: "https://apim.wildfireapps-dev.alberta.ca/flight-reports-api-uat/api/v1.0/",
//   headers: {
//     //Authorization: `Bearer ${token}`,
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "X-CSRF": "1",
//   },
// });

// export const httpAviationApimUat= axios.create({
//   baseURL: "https://goa-wdp-apim-uat.azure-api.net/flight-reports-api/",
//   headers: {
//     //Authorization: `Bearer ${token}`,
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "X-CSRF": "1",
//   },
// });

const envAviation: aviationAppEnv = {
  local: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/flight-reports-api-uat/api/v1.0/",
  },
  dev: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/flight-reports-api-uat/api/v1.0/",
  },
  test: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/flight-reports-api-uat/api/v1.0/",
  },
  uat: {
    baseURL: "https://goa-wdp-apim-uat.azure-api.net/flight-reports-api/api/v1.0/",
  },
  prod: {
    baseURL: "https://goa-wdp-apim.azure-api.net/flight-reports-api/api/v1.0/",
  }
};

const appEnv = process.env.REACT_APP_ENV as keyof aviationAppEnv;
//const appEnv = "uat" as keyof aviationAppEnv;

export const httpAviationApim= axios.create({
  baseURL: `${envAviation[appEnv].baseURL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF": "1",
  },
});