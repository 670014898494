import {
  GoATable,GoACallout,
} from "@abgov/react-components";
import * as React from "react";
import { IFlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import TotalFlyingHoursColumn from "./TotalFlyingHoursColumn";

interface IViewFlyingDetailProps {
  flyingHoursPaginationResult: IPaginationResult<IFlyingHours> | undefined;
  warningMessage: string;
}

const ViewFlyingDetail: React.FunctionComponent<IViewFlyingDetailProps> = ({flyingHoursPaginationResult,warningMessage, ...props
}) => {
  return (
    <>
      <div className="white-background horizontal-scrollbar">
            <GoATable width="100%">
              <thead>
                <tr style={{ position: "sticky", top: 0 }}>
                  <th className="width145px">Fire number</th>
                  <th className="width230px">Crew type on board</th>
                  <th className="width230px">Activity</th>
                  <th className="width120px">Rate type</th>
                  <th className="width120px">No.of units</th>
                  <th className="width110px">Unit</th>
                  <th className="width140px">Rate per unit</th>
                  <th className="width150px">Cost</th>
                  <th className="width150px">Internal order</th>
                  <th className="width150px">Cost centre</th>
                  <th className="width80px">Fund</th>
                  <th className="width100px">Account</th>
                  <th className="width150px">Invoice no.</th>
                </tr>
              </thead>

              <tbody>
                  {flyingHoursPaginationResult && flyingHoursPaginationResult.data !== null ? 
                    flyingHoursPaginationResult.data.map((record: any, idx: number) => (
                      <tr key={idx}>
                      <td>
                        {record?.fireNumberEntity && record?.fireYear ? record?.fireNumberEntity?.fireNumber + "-" + record?.fireYear : 
                          record?.fireNumberEntity ? record?.fireNumberEntity?.fireNumber : ""}
                      </td>
                      <td>
                        {record?.crewTypeEntity ? record?.crewTypeEntity?.code + "-" + record?.crewTypeEntity?.description : ""}
                      </td>
                      <td>
                        {record?.costingActivityEntity ? record?.costingActivityEntity?.code + " " + record?.costingActivityEntity.description : ""}
                      </td>
                      <td>
                        {record?.rateTypeEntity ? record?.rateTypeEntity?.type : ""}
                      </td>
                      <td>
                        {record?.noOfUnits.toFixed(1)}
                      </td>
                      <td>
                        {record?.rateUnitEntity ? record?.rateUnitEntity?.type : ""}
                      </td>
                      <td>{"$"+record?.ratePerUnit.toFixed(2)}</td>
                      <td>{"$"+record?.cost.toFixed(2)}</td>
                      <td>
                        {record?.internalOrder}
                      </td>
                      <td>
                        {record?.costCenter}
                      </td>
                      <td>
                        {record?.fund}
                      </td>
                      <td>
                        {record?.account}
                      </td>
                      <td>
                        {record?.invoiceNumber}
                      </td>
                    </tr>
                  )) : 
                  <></>
                  }
                
                  {/* Total flying hours row */}
                  <tr>
                    <TotalFlyingHoursColumn displayText={"Total flying hours"} flyingHoursPaginationResult={flyingHoursPaginationResult} />
                  </tr>
               
                </tbody>
            </GoATable>
          </div>

          <div>&nbsp;</div>
            {(warningMessage !== undefined && warningMessage !== "") ? (
              <GoACallout type="important" heading="Total number of units does not equal meter difference" size="medium">
                {warningMessage !== 'Add a note to explain why.' ? 'Add a note to explain why.' : warningMessage}
              </GoACallout> )
            : (
            <></>
            )}  
    </>
  );
};

export default ViewFlyingDetail;
