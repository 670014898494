import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { USStandToStandDown } from "../../model/Unserviceable/IUSStandToStandDown";

const getById = async (id: string, strToken: string = "") => {
  const data = await httpAviationApim.post<USStandToStandDown>(
    `/unserviceable-standtostanddown/get/${id}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const create = async (unserviceableStandToStandDown: USStandToStandDown, strToken:string = "") => {
  const params = JSON.stringify(unserviceableStandToStandDown);
 
  const data = await httpAviationApim.post<USStandToStandDown>(
    `/unserviceable-standtostanddown/create`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

const update = async (uSStandToStandDown: USStandToStandDown, strToken:string = "") => {
  const params = JSON.stringify(uSStandToStandDown);
 
  const data = await httpAviationApim.put<USStandToStandDown>(
    `/unserviceable-standtostanddown/update/${uSStandToStandDown.flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

export const StandToStandDownTimeService = {
  getById,
  create,
  update
};

