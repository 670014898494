import { GoAFormItem, GoATable } from '@abgov/react-components';
import * as React from 'react';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { IFlightExpenses } from '../../model/IFlightExpense';

interface IViewExpenseDetailsProps {
  expensesData: IPaginationResult<IFlightExpenses> | any | undefined;
}

const ViewExpenseDetails: React.FunctionComponent<IViewExpenseDetailsProps> = ({ expensesData, ...props }) => {
  return (
    <>
      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width50">
            <div className="row accordion fuel">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Fuel</h3>
                </div>
                <div className="content">
                  <div className='width50 col-md-6'>
                    <GoAFormItem label="Provider">
                      <label>{expensesData?.flightOtherExpenses?.provider}</label>
                    </GoAFormItem>
                  </div>
                  <div className='width50 col-md-6'>
                    <GoAFormItem label="Litres">
                      <label>{expensesData?.flightOtherExpenses?.litres}</label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right width50 paddingLeft20px">
            <div className="row accordion flight-crew">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Double Crew</h3>
                </div>
                <div className="content">
                  <div className='width100 col-md-6'>
                    <GoAFormItem label="">
                      <label>{expensesData?.flightOtherExpenses?.doubleCrew === false ? "No" : "Yes"}</label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row accordion flight-crew-eligibility section-margin-top-20">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">
              Flight Crew Eligibility
            </h3>
          </div>
          <div className="content">
            <div className="white-background">
              <GoATable width="100%">
                <thead>
                  <tr style={{ position: "sticky", top: 0 }}>
                    <th className="width15">Name</th>
                    <th className="width15">Role</th>
                    <th className="width15">Breakfast</th>
                    <th className="width15">Lunch</th>
                    <th className="width15">Dinner</th>
                    <th className="width10">Lodging</th>
                    <th className="width10">Vehicle</th>
                  </tr>
                </thead>

                {<tbody>
                  {expensesData?.flightCrewEligibility?.map(
                    (record: any, idx: number) => (
                      <tr key={idx}>
                        <td>
                          {record.name
                          }
                        </td>
                        <td>
                          {record.role}
                        </td>
                        <td>
                          {record.breakfast}
                        </td>
                        <td>
                          {record.lunch}
                        </td>
                        <td>{record.dinner}</td>
                        <td>
                          {record.lodging}
                        </td>
                        <td>{record.vehicle}</td>
                      </tr>
                    )
                  )
                  }
                  <></>
                </tbody>}
              </GoATable>
            </div>
          </div>
        </div>
      </div>
      <div className='horizontal-scrollbar'>
        <GoATable width="100%">
          <thead>
            <tr style={{ position: "sticky", top: 0 }}>
              <th className="width10">Fire number</th>
              <th className="width8">Activity</th>
              <th className="width10">Rate type</th>
              <th className="width8">No.of units</th>
              <th className="width4">Unit</th>
              <th className="width8">Rate per unit</th>
              <th className="width10">Cost</th>
              <th className="width10">Internal order</th>
              <th className="width10">Cost center</th>
              <th>Fund</th>
              <th className="width8">Account</th>
              <th className="width10">Invoice no.</th>
            </tr>
          </thead>

          <tbody>
            {
              expensesData?.expenseDetails?.map(
                (record: any, idx: number) => (
                  <tr key={idx}>
                    <td>
                      {record?.fireNumber && record?.fireYear ? record?.fireNumber + "-" + record?.fireYear : 
                          record?.fireNumber ? record?.fireNumber : ""}
                    </td>
                    <td>
                      {record.costingActivityCode}
                    </td>
                    <td>
                      {record?.rateTypeEntity ? record?.rateTypeEntity?.type : ""}
                    </td>
                    <td>{record.noOfUnits.toFixed(2)}</td>
                    <td>
                      {record?.rateUnitEntity ? record?.rateUnitEntity?.type : ""}
                    </td>
                    <td>{"$" + record.ratePerUnit.toFixed(2)}</td>
                    <td>
                      {"$" + record.cost.toFixed(2)}
                    </td>
                    <td>{record.internalOrder}</td>
                    <td>{record.costCenter}</td>
                    <td>{record.fund}</td>
                    <td>{record.account}</td>
                    <td>{record?.invoiceNumber}</td>
                  </tr>
                )
              )
            }

          </tbody>
        </GoATable>
      </div>
    </>
  );
};

export default ViewExpenseDetails;
