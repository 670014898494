import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";
import { FlyingHours, IFlyingHours } from "../../model/IFlyingHours";

//Search
const getSearch = async (objISearch: ISearch,flightReportId:number, strToken:string = "") => {
    const params = JSON.stringify({
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        columnName: objISearch.filterBy?.columnName,
        columnValue: objISearch.filterBy?.columnValue
      },
      paginationInfo: {
        perPage: objISearch.pagination?.perPage,
        page: objISearch.pagination?.page,
      },
    });
  
    const data = await httpAviationApim.post<IPaginationResult<IFlyingHours>>(
      `/flying-hours/get/${flightReportId}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
   
    return data;
  };

  const getById = async (id: string, strToken: string = "") => {
    const data = await httpAviationApim.post<IFlyingHours>(
      `/flying-hours/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };

  const createUpdate = async (arrFlyingHours:Array<FlyingHours>, flightReportId:number, strToken: string = "") => {
    const params = JSON.stringify(arrFlyingHours);
    
    const data = await httpAviationApim.put<Array<FlyingHours>>(
      `/flying-hours/create-update/${flightReportId}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    return data;
  
  };
  
  export const FlyingHoursService = {
    getSearch,
    getById,
    createUpdate,
  };

  export const calculateTotalFlyingHours = (flyingHoursPaginationResult:IPaginationResult<IFlyingHours> | undefined, noOfDecimal:number = 2) => {
    var totalFlyingHours = 0;

    if(flyingHoursPaginationResult?.data)
    {
      flyingHoursPaginationResult?.data.forEach((record) =>  { totalFlyingHours += record.noOfUnits })
      return totalFlyingHours.toFixed(noOfDecimal);
    }
    else{
      return totalFlyingHours;
    }
  }

