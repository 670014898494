import axios from "axios";
import { aviationAppEnv } from "../model/config/IConfigSetting";

// export const httpAviationDomainApimDev= axios.create({
//     //baseURL: "https://apim.wildfireapps-dev.alberta.ca/domain-tables-dev/api/v1.0/",
//     baseURL: "https://goa-wdp-apim-uat.azure-api.net/domain-tables/",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       "X-CSRF": "1",
//     },
//   });

  const envDomainService: aviationAppEnv = {
    local: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/domain-tables-dev/api/v1.0/",
    },
    dev: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/domain-tables-dev/api/v1.0/",
    },
    test: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/domain-tables-dev/api/v1.0/",
    },
    uat: {
      baseURL: "https://goa-wdp-apim-uat.azure-api.net/domain-tables/api/v1.0/",
    },
    prod: {
      baseURL: "https://goa-wdp-apim.azure-api.net/domain-tables/api/v1.0/",
    }
  };
  
  const appEnv = process.env.REACT_APP_ENV as keyof aviationAppEnv;
  //const appEnv = "uat" as keyof aviationAppEnv;
  
  export const httpDomainServiceApim= axios.create({
    baseURL: `${envDomainService[appEnv].baseURL}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF": "1",
    },
  });