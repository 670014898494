import { GoABadge, GoAButton, GoAButtonGroup, GoAFormItem, GoAIcon, GoAModal } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { FlightReport } from '../../model/IFlightReport';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import { AuthenticationService } from '../../services/authentication/AuthenticationService';
import FlightReportFlagged from './FlightReportFlagged';
import axios from "axios";
import { IValidationError } from "../../model/errors/IValidationError";
import CalloutVariant from "../../model/enum/CalloutVariant";
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { ICorporateRegion } from '../../model/ICorporateRegion';
import { getCorporateRegion } from '../../services/domainServices/CorporateRegionDomainService';

interface IFlightReportDetailHeaderProps {
    flightReport: FlightReport;
    corporateRegionResult: IPaginationResult<ICorporateRegion>;
    isEditing?:boolean;
    onUpdate: (value: FlightReport) => void;
    onChange: (value: FlightReport) => void;
}

const FlightReportDetailHeader: React.FunctionComponent<IFlightReportDetailHeaderProps> = ({flightReport, corporateRegionResult, isEditing,onUpdate,onChange,...props}) => {
  const auth = useAuth();
  // Navigation
  const navigate = useNavigate();

  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [headerModal, setHeaderModal] = React.useState("Are you sure you want to sign off on this report?");
  const [cancelButton, setCancelButton] = React.useState("No, cancel");
  const [submitButton, setSubmitButton] = React.useState("Yes, I do");

  const [returnedButton, setReturnedButton] = React.useState(false);
  const [approvedButton, setApprovedButton] = React.useState(false);
  const [deleteButton, setDeleteButton] = React.useState(false);
  const [unsignButton, setUnsignButton] = React.useState(false);

  const [notificationMessage, setNotificationMessage] = React.useState("");
  const [warningMessage, setWarningMessage] = React.useState("");
  const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
  const [showWarningMessage, setShowWarningMessage] = React.useState(false);
  const [calloutVariant, setCalloutVariant] = React.useState(CalloutVariant.success);
  const [modalHeading] = React.useState("");
  
  async function onSignedOff()
  {
    setReturnedButton(false);
    setApprovedButton(false);
    setDeleteButton(false);
    setUnsignButton(false);
    setHeaderModal("Are you sure you want to sign off on this report?");
    setCancelButton("No, cancel");
    setSubmitButton("Yes, I do");
    setShowModal(true);
  }

  async function onUnSignOff()
  {
    setReturnedButton(false);
    setApprovedButton(false);
    setDeleteButton(false);
    setUnsignButton(true);
    setHeaderModal("Are you sure you want to unsign off on this report?");
    setCancelButton("No, cancel");
    setSubmitButton("Yes, I do");
    setShowModal(true);
  }

  async function onReturned()
  {
    setReturnedButton(true);
    setApprovedButton(false);
    setDeleteButton(false);
    setUnsignButton(false);
    setHeaderModal("Are you sure you want to return on this report?");
    setCancelButton("Cancel");
    setSubmitButton("Yes, Return");
    setShowModal(true);
  }

  async function onApproved()
  {
    setApprovedButton(true);
    setReturnedButton(false);
    setDeleteButton(false);
    setUnsignButton(false);
    setHeaderModal("Are you sure you want to approve on this report?");
    setCancelButton("Cancel");
    setSubmitButton("Yes, Approve");
    setShowModal(true);
  }

  async function onCancel() {
    setIsLoading(true);
    setShowModal(false);
    setDeleteButton(false);
    setUnsignButton(false);

    setIsLoading(false);
  }

  async function onDelete()
  {
    setReturnedButton(false);
    setApprovedButton(false);
    setDeleteButton(true);
    setUnsignButton(false);
    setHeaderModal("You are about to delete this flight report, flying hours, expenses, notes and unserviceable information. Do you want to proceed?");
    setCancelButton("No, cancel");
    setSubmitButton("Yes, I do");
    setShowModal(true);
  }

  //On signed off yes submit button
  async function onSubmit(status:string = FlightReportStatus.signedOff, ignoreWarning:boolean = true) {
    setIsLoading(true);

    let newValue = new FlightReport();
    if (status !== FlightReportStatus.inProgress)
        newValue =  new FlightReport({...flightReport, isFlagged:false, status:status});
    else  
        newValue =  new FlightReport({...flightReport, status:status});

    //Get corporate region if it get undefined
    if(newValue.corporateRegion === undefined && newValue.corporateRegionId)
    {
      var corporateRegion = getCorporateRegion(
        corporateRegionResult,
        newValue.corporateRegionId
      );

      newValue.corporateRegion = corporateRegion;
    }

    try{
      //Update flag status
      await FlightReportCRUD.createAndUpdate(newValue, auth, auth.user?.access_token as string, ignoreWarning)
      .then((response: any) => {
        //pass update value to parent to FlightReportDetail
        onUpdate(newValue);
        setWarning(response.data);
      })
      .catch((error) => {               
        setError(error);
      });

      setShowModal(false);
      
      setIsLoading(false);
    }
    catch(e:any)
    {
      console.log(e);
      setShowModal(false);
      setIsLoading(false);
    }
  }

  //On click delete button
  async function onSubmitDelete() {
    setIsLoading(true);

    let newValue = new FlightReport();
    newValue =  new FlightReport({...flightReport});

    try{
      await FlightReportCRUD.deleteFlightReportById(newValue, auth, auth.user?.access_token as string)
      .then((response: any) => {
        navigate("/dashboard");
      })
      .catch((error) => {
        setError(error);
      });
      setShowModal(false);
      setIsLoading(false);
    }
    catch(e:any)
    {
      console.log(e);
      setShowModal(false);
      setIsLoading(false);
    }
  }

      //on property values change
      function onFlagStatusChange(record:FlightReport) {
        setIsLoading(true);
  
        let newValue = new FlightReport();
  
        newValue =  new FlightReport({...record});
  
        //send on change to parent component
        onChange(newValue);
  
        setIsLoading(false);
      }

      //set validation error
  async function setError(error: any) {
    var validationError = "";

    if (axios.isAxiosError(error)) {
      if (error.response) {
        var validation = error.response.data as IValidationError;
        if (validation) {
          validation.validationResult?.forEach((record: any, index: any) => {
            if (record && record.type === "Error") 
              validationError += "<li>" + record.message + "</li>";
          });
        }

        if (validation?.errorMessage){
          validationError = validationError + "<p>" + validation.errorMessage + "</p>";
        } 

        if (validationError !== "") {
          setCalloutVariant(CalloutVariant.emergency);
          setNotificationMessage(validationError);
          setShowNotificationMessage(true);
          setIsLoading(false);
        }
        else setWarning(error.response.data);

      }
    } else {
      console.error("else", error);
    }
  }

  async function setWarning(warning: any) {
    var validationWarning = "";

    if (warning)
    {
        warning.validationResult?.forEach((record: any, index: any) => {
          if (record && record.type === "Warning") validationWarning += "<li>" + record.message + "</li>";                          
        });
        if (validationWarning !== "")             
            setShowWarningMessage(true);            
        else            
            setShowWarningMessage(false);

        setWarningMessage(validationWarning);
    }
  }
  
  return (
    <>
      <PageLoader visible={loading} />

      <div className="new-layout-main">
        <div className="new-layout-header">
          <div>
            <GoAButton type="tertiary" leadingIcon="arrow-back" onClick={()=>navigate("/dashboard")}>
              Back to Dashboard
            </GoAButton>
          </div>
          <div className="new-layout-header-section">
            <div className="form-row">
              <div className="col-50">
                <div className="contentInline detailHeader">
                  <div className="heading left paddingLeft0px">
                    <label>
                      {flightReport?.flightReportId
                        ? "Flight Report " + flightReport?.flightReportId ??
                          "..."
                        : "Create new flight report"}
                    </label>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Flight Date">
                      <label className='detailsValue'>
                        {flightReport?.flightReportDate ? moment(flightReport?.flightReportDate).format(
                          "yyyy-MM-DD"
                        ) : "-"}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Contract Registration">
                      <label className='detailsValue'>
                        {flightReport?.contractRegistration ?
                          flightReport?.contractRegistration
                            ?.aircraftRegistration : "-"
                        }
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Status">
                      <label>
                        {flightReport?.status ===
                        FlightReportStatus.returned ? (
                          <GoABadge type="emergency" content="Returned" />
                        ) : flightReport?.status ===
                          FlightReportStatus.signedOff ? (
                          <GoABadge type="information" content="Signed off" />
                        ) : flightReport?.status ===
                          FlightReportStatus.inProgress ? (
                          <GoABadge type="information" content="In progress" />
                        ) : flightReport?.status ===
                          FlightReportStatus.approved ? (
                          <GoABadge type="information" content="Approved" />
                        ) : flightReport?.status ===
                          FlightReportStatus.processed ? (
                          <GoABadge type="information" content="Processed" />
                        ): (
                          "-"
                        )}
                      </label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>

              <div className="col-50 headingOptions">
                <div className="right">
                  <div className="col-15 padding-left-right10">
                    <GoAIcon type="information-circle" />
                  </div>
                  <div className="col-15 padding-left-right10">
                    
                      <FlightReportFlagged
                        flightReport={flightReport}
                        onFlagChange={onFlagStatusChange}
                      />
                    
                  </div>

                  {(flightReport?.status === FlightReportStatus.inProgress || flightReport?.status === FlightReportStatus.returned) ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_D',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="secondary"                          
                          onClick={() => onDelete()}
                        >
                          Delete
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}

                  {flightReport?.status === FlightReportStatus.inProgress || flightReport?.status === FlightReportStatus.returned ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                          && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptSignoff_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          disabled={
                            isEditing
                          }
                          onClick={() => onSignedOff()}
                        >
                          Sign off
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}
                  
                  {flightReport?.status === FlightReportStatus.signedOff ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                          && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptSignoff_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"                         
                          onClick={() => onUnSignOff()}
                        >
                          Unsign off
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}


                  {flightReport?.status === FlightReportStatus.signedOff || flightReport?.status === FlightReportStatus.approved? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                        && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptAppRet_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          onClick={() => onReturned()}
                        >
                          Return
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}

                  {flightReport?.status === FlightReportStatus.signedOff ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                        && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptAppRet_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          onClick={() => onApproved()}
                        >
                          Approve
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------- */}

      <GoAModal
        heading=""
        open={showModal}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton
              size="compact"
              type="secondary"
              onClick={() => onCancel()}
            >
              {cancelButton}
            </GoAButton>
            <GoAButton
              size="compact"
              type="primary"
              onClick={() =>
                returnedButton ? onSubmit(FlightReportStatus.returned) :
                approvedButton ? onSubmit(FlightReportStatus.approved) :
                deleteButton ? onSubmitDelete() :
                unsignButton ? onSubmit(FlightReportStatus.inProgress) :
                onSubmit(FlightReportStatus.signedOff, false)
              }
            >
              {submitButton}
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>{headerModal}</p>
      </GoAModal>

      {/* Popup message */}
      {flightReport?.status !== FlightReportStatus.inProgress && flightReport?.status !== FlightReportStatus.returned && <div>
      <GoAModal
          heading={modalHeading}
          calloutVariant={calloutVariant}
          open={showNotificationMessage}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => setShowNotificationMessage(false)}>
                Okay
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
        </GoAModal>
      </div>}

      {(flightReport?.status === FlightReportStatus.inProgress || flightReport?.status === FlightReportStatus.returned) && <div>
      <GoAModal             
          heading="This flight report cannot be signed off"             
          open={showNotificationMessage}
          onClose={() => setShowNotificationMessage(false)}
          actions={
            <GoAButtonGroup alignment="end">             
            </GoAButtonGroup>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
        </GoAModal>
        </div>} 

        {(flightReport?.status === FlightReportStatus.inProgress || flightReport?.status === FlightReportStatus.returned) && 		
		      (showNotificationMessage === undefined || showNotificationMessage === false) && returnedButton === false && deleteButton === false && unsignButton === false &&
          warningMessage !== undefined && warningMessage !== "" && <div>
          <GoAModal             
            heading="This flight report cannot be signed off"                     
            open={showWarningMessage}
            onClose={() => setShowWarningMessage(false)}
            actions={
              <GoAButtonGroup alignment="end">  
                <GoAButton onClick={() => setShowWarningMessage(false)}>
                  No, cancel
                  </GoAButton>  
                  <GoAButton onClick={() => onSubmit(FlightReportStatus.signedOff, true)}>
                    Yes, continue
                  </GoAButton>          
              </GoAButtonGroup>
            }
           >
            <div dangerouslySetInnerHTML={{ __html: warningMessage }} />
          </GoAModal>
        </div>}  

    </>

    
  );

  
};

export default FlightReportDetailHeader;
