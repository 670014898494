import { httpDomainServiceApim } from "../../utils/domainServiceAPIMConfig";
import { ICorporateRegion } from "../../model/ICorporateRegion";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
    const params = JSON.stringify({
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        columnName: "",
        columnValue: ""
      },
      paginationInfo: {
        perPage: objISearch.pagination?.perPage,
        page: objISearch.pagination?.page,
      },
    });
  
    // const data = await httpDomainService.post<IPaginationResult<ICorporateRegion>>(
    //   "/corporate_region/get",
    //   params,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${sessionStorage.getItem("domainService_token")}`,
    //     },
    //   }
    // );

    const data = await httpDomainServiceApim.post<IPaginationResult<ICorporateRegion>>(
      "corporate_region/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<ICorporateRegion>(
      `/corporate_region/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };
  
  export const CorporateRegionDomainService = {
    getSearch,
    getById,
  };


//Forest Area is corporate region
export const getCorporateRegion = (corporateRegionPaginationResult:IPaginationResult<ICorporateRegion>, corporateRegionId:string) => {
  if(corporateRegionPaginationResult != null)
  {
    const result = corporateRegionPaginationResult.data.find(value => value.corporateRegionId === corporateRegionId);
    return result;
  }
  else{
    return undefined;
  }
}


export const bindMutltiSelectDropdown = (corporateRegionPaginationResult:IPaginationResult<ICorporateRegion>): {label:string,value:string}[] => {
    const options = [];
    
    if(corporateRegionPaginationResult != null)
    {
      for (let i = 0; i < corporateRegionPaginationResult.data.length; i++) {
        let obj = {label: corporateRegionPaginationResult.data[i].name.toString(), value: corporateRegionPaginationResult.data[i].corporateRegionId};
        options.push(obj);
      }
    }

    return options;
  }

