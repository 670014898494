import moment from "moment";
import { IHiringContract } from "../model/HiringContract/IHiringContract";
import { FlightExpenses } from "../model/IFlightExpense";
import { FlightReport } from "../model/IFlightReport";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { RateTypeService } from "../services/domainServices/RateTypeService";
import { RateUnitService } from "../services/domainServices/RateUnitService";
import { ExpenseService } from "../services/expense/ExpensesService";
import { HiringContractService } from "../services/HiringContractService";

//Rate type
async function getRateTypes(flightReportDate:string,searchValue:string = "",perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    negotiated: undefined,
    columnName: "",
    columnValue:""
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting expenses",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateTypeService.getSearch(objISearch);
  return response;
}

//Rate unit
async function getRateUnits(flightReportDate:string,searchValue:string = "",perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "activeDate",
    columnValue: moment(flightReportDate).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting expenses",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateUnitService.getSearch(objISearch);
  return response;
}

async function getHiredVendorContractRates(flightReport:FlightReport, strToken:string = "")
{
    var response = await HiringContractService.getRates(flightReport?.contractRegistration as IHiringContract, flightReport.flightReportDate, strToken);
    return response.data;
}

const createAndUpdate = async (flightExpenses:FlightExpenses, flightReportId:number, strToken:string) => {
  var response = await ExpenseService.createUpdate(flightExpenses, flightReportId, strToken);
  return response;
};

//Sorting expense details based on sort column and sort direction
const sortingExpenseData = (expensesDataParam: FlightExpenses | undefined, flightExpensesApiResult: FlightExpenses, sortCol:string = "rateType", sortDir:number = -1) => {
  const _expenseDetails = [...(flightExpensesApiResult.expenseDetails || [])];
  _expenseDetails.sort((a, b) => {
    return (
      (a[sortCol as keyof typeof expensesDataParam] >
      b[sortCol as keyof typeof expensesDataParam]
        ? -1
        : 1) * sortDir
    );
  });

  flightExpensesApiResult.expenseDetails = _expenseDetails;

  return flightExpensesApiResult;
}

export const ExpenseDetailCRUD = {
    getRateTypes,
    getRateUnits,
    getHiredVendorContractRates,
    createAndUpdate,
    sortingExpenseData,
  };