import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./App.css";
import "./newLayout.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import {RecoilRoot} from 'recoil';
import { useAuth } from "react-oidc-context";

function App() {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      if(window.location.search)
      {
        window.location.href = window.location.origin;
      }
      else{
        auth.signinRedirect();
      }
    }
  }, [auth]);
  
  return (
    <RecoilRoot>
      <BrowserRouter>
      {!auth.isLoading &&
        <Layout /> 
      }
      {/* {auth.isLoading && <PageLoader visible={true} />} */}
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
