
import * as React from "react";
import FlightReportList from "../flightReport/FlightReportList";

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  return (
    <>

      <FlightReportList />

    </>
  );
};

export default Dashboard;
