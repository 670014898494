const envKC: KeycloakEnv = {
  local: {
    realm: "e8a54e8b-ca4a-404a-9ba1-00b593cd3ff8",
    authServerUrl: "https://access.adsp-dev.gov.ab.ca/auth",
  },
  dev: {
    realm: "e8a54e8b-ca4a-404a-9ba1-00b593cd3ff8",
    authServerUrl: "https://access.adsp-dev.gov.ab.ca/auth",
  },
  test: {
    realm: "e8a54e8b-ca4a-404a-9ba1-00b593cd3ff8",
    authServerUrl: "https://access.adsp-dev.gov.ab.ca/auth",
  },
  uat: {
    realm: "1405810f-b8a8-4eb6-b22a-e9e89347420b",
    authServerUrl: "https://access-uat.alberta.ca/auth",
  },
  prod: {
    realm: "c9f3b441-e005-4f23-a028-2e2850c7ea85",
    authServerUrl: "https://access.alberta.ca/auth",
  }
};
interface KeycloakEnv {
  local:KCInfo;
  dev: KCInfo;
  test:KCInfo;
  uat: KCInfo;
  prod: KCInfo;
}
interface KCInfo {
  realm: string;
  authServerUrl: string;
}

const appEnv = process.env.REACT_APP_ENV as keyof KeycloakEnv;
//const appEnv = "local" as keyof KeycloakEnv;

export const oidcConfig: any = {
  authority: `${envKC[appEnv].authServerUrl}/realms/${envKC[appEnv].realm}`,
  client_id: "FlightReports-app",
  redirect_uri: window.location.href,
  pkce_method: "S256",
  extraQueryParams: {
    kc_idp_hint: "oidc"
  }
};