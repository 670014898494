import moment from "moment";
import { EventHandler } from "react";
import FlightReportStatus from "../model/enum/FlightReportStatus";
import { IArrayOption } from "../model/IArrayOption";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { IUSLog } from "../model/Unserviceable/IUSLog";
import { validateHoursMinutes } from "./checkInput";

export const isNullOrEmpty = (value: any) => {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

export const getSetEndDate = (value: Date, offset: number) => {
  const d = value;
  d.setDate(d.getDate() + offset);
  return d;
}

export const sortingData = (paginationResult: any, sortCol: string, sortDir: any = "ASC") => {
  const _result = [...(paginationResult.data || [])];
  _result.sort((a, b) => {
    return (
      (a[sortCol as keyof typeof paginationResult] >
        b[sortCol as keyof typeof paginationResult]
        ? -1
        : 1) * sortDir
    );
  });
}

export const onlyNumericRegularExp = () => {
  return /^[0-9\b]+$/;
}

export const keyPress: EventHandler<React.KeyboardEvent<HTMLInputElement>> = (e) => {
  if (e.key === 'Enter') {
    console.log('Do login', (e.target as HTMLInputElement).value)
  }
}

export const onlyNumbersKey = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
  var ASCIICode = (event.which) ? event.which : event.keyCode

  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    event.preventDefault();
  }
}

export const toNumber = (value: string | number) => {
  if (typeof value === 'number') return value
  return parseInt(value.replace(/[^\d]+/g, ''));
}

export const checkLogin = () => {
  if ((sessionStorage.getItem("username") != null && sessionStorage.getItem("username") !== "") &&
    (sessionStorage.getItem("password") != null && sessionStorage.getItem("password") !== "")) {
    return true;
  }
  else {
    return false;
  }
}

export const convertObjectFromOneSingleArrayValue = (obj: []) => {
  return Object.assign({}, ...obj as unknown as []);
}

//Calculate cost based on noOfUnits & ratePerUnit
export const calculateCost = (noOfUnits: any,ratePerUnit:any) => {
  //Check if noOfUnits is decimal
  if(noOfUnits)
  {
    if (!(noOfUnits.toString().indexOf(".") === -1)){
      // decimal 
      var decimalCount = noOfUnits.toString().split('.')[1].length;
      if(decimalCount > 2)
      {
        const roundedNoOfUnits = Math.round(Number(noOfUnits) * 100) / 100;
        noOfUnits = roundedNoOfUnits.toString();
      }
    }
  }

  //Calculate noOfUnits && ratePerUnit
  if(noOfUnits && ratePerUnit)
  {
    var multiplyCost = (noOfUnits) * (ratePerUnit);
    const roundedCost = parseFloat(multiplyCost.toFixed(2).toString());
    return roundedCost;
  }
  else
    return 0.00;
}

export const validate = (ruleCode: string, elementName: string = '', eventName: string = '',value:any='') => {
  const funcStartStr = 'function validate(elementName, event, value) {const errorMessages=[]; let isValid=true;'
  const functionEndingStr = '	return errorMessages;}return validate(elementName, eventName,value);';
 
  //console.log("validate", funcStartStr + ruleCode + functionEndingStr);
  return run(elementName, eventName, value,funcStartStr + ruleCode + functionEndingStr)
};

const run = (elementName: string, eventName: string, value:any, ruleCode: string) => {
  try {
    
    // Create a new function using the script content
    // eslint-disable-next-line no-new-func
    //console.log(ruleCode);
    // eslint-disable-next-line no-new-func
    const dynamicFunction = new Function('elementName', 'eventName','value', ruleCode);

    // Execute the function with the parameter and get the result
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return dynamicFunction(elementName, eventName,value);

    // Update the state with the script's output
    //setScriptOutput(scriptResult);
  } catch (error) {
    console.error('Error executing script:', error);
  }
};

export const getRequireActionFilterStatus = (statusCountSelected:IArrayOption[],) => {
  var strStatus="";

  if(statusCountSelected)
  {
    if(statusCountSelected.find(x=> (x.value === FlightReportStatus.inProgress || x.value === FlightReportStatus.returned)))
    {
      for (let i = 0; i < statusCountSelected.length; i++) {
        if(statusCountSelected[i]["value"] === FlightReportStatus.inProgress || statusCountSelected[i]["value"] === FlightReportStatus.returned)
        {
          if(strStatus === "")
            strStatus = "'" + statusCountSelected[i]["value"] + "'";
          else
            strStatus += ",'" + statusCountSelected[i]["value"] + "'";
        }
      }
    }
  }
  
  return strStatus;
} 

export const getFilterStatus = (statusCountSelected:IArrayOption[],) => {
  var strStatus="";

  if(statusCountSelected)
  {
    for (let i = 0; i < statusCountSelected.length; i++) {
      if(strStatus === "")
        strStatus = "'" + statusCountSelected[i]["value"] + "'";
      else
        strStatus += ",'" + statusCountSelected[i]["value"] + "'";
    }
  }
  
  return strStatus;
} 

export const removeStringCharacters = (value:any, totalLastcharacterRemoved:number = 3) =>
{
  if(value)
  {
    var strSubstr = value;
    return strSubstr.substr(0, strSubstr.length - totalLastcharacterRemoved);
  }
  else{
    return "";
  }
}

//Calculate total hours based on Stand To, Start time, Stand Down, end time
export const calculateHoursAndMinutes = (unserviceableDate:any ,standTo: any, standDown:any, hoursMinutesLength:number = 2) => {
  if(unserviceableDate && standTo && standDown)
  {
    var isValidStandToHoursMinutes = validateHoursMinutes(standTo, 2);
    var isValidStandDownHoursMinutes = validateHoursMinutes(standDown, 2);

    if(isValidStandToHoursMinutes && isValidStandDownHoursMinutes)
    {
      let dtStandTo = new Date(moment(unserviceableDate).format("yyyy-MM-DD") + " " + standTo);
      let dtStandDown = new Date(moment(unserviceableDate).format("yyyy-MM-DD") + " " + standDown);

      if(!isNaN(dtStandTo.getTime()) && !isNaN(dtStandDown.getTime()))
      {
        var startDateTime = moment(dtStandTo).format("yyyy-MM-DD HH:mm:ss");
        var endDateTime = moment(dtStandDown).format("yyyy-MM-DD HH:mm:ss");

        if(startDateTime && endDateTime)
        {
          var hours = moment.utc(moment(endDateTime).diff(startDateTime)).format("HH");
          var mins = moment.utc(moment(endDateTime).diff(startDateTime)).format("mm");

          if(hours !== "Invalid date" && mins !== "Invalid date")
            return hours + ":" + mins.toString();
          else
            return 0;

        }
        else{
          return 0;
        }
      }
      else{
        return 0;
      }
    }
    else{
      return 0;
    }
  }
  else
    return 0;
}

export const calculateTotalHours = (unserviceableDate:any ,standTo: any, standDown:any) => {
  var totalHours = 0, totalMinutes = 0, hours:any, minutes:any, hoursMinutes:any;
 
  if(unserviceableDate && standTo && standDown)
  {
    hoursMinutes = calculateHoursAndMinutes(unserviceableDate, standTo, standDown);

    if(hoursMinutes !== 0)
    {
      if (!(hoursMinutes.indexOf(":") === -1)){
        //Hours
        hours = hoursMinutes.split(':')[0];
        // Minutes 
        minutes = hoursMinutes.split(':')[1];

        if(hours !== "" && hours !== "00" && hours > 0)
          totalHours += Number(hours);
        
        if(minutes !== "" && minutes !== "00" && minutes > 0)
          totalMinutes += Number(minutes);
      }
    }

    if(totalHours >= 0 && totalMinutes >= 0)
    {
      totalMinutes = minuteCalculate(totalMinutes);

      if(totalHours >= 0 && totalMinutes === 1)
        return (totalHours + 1).toString();
      else
        return totalHours + "." + totalMinutes;
    }
    else{
      return "0.0";
    }
  }
  else{
    return "0.0";
  }
}

//Calculcate unserviceable log hours & minutes
export const calculateUSLogs = (uSLogPaginationResult:IPaginationResult<IUSLog> | undefined, noOfDecimal:number = 2) => {
  var totalHours = 0, totalMinutes = 0, hours:any, minutes:any, hoursMinutes:any;

  if(uSLogPaginationResult?.data)
  {
    uSLogPaginationResult?.data.forEach((record) =>  { 
      hoursMinutes = calculateHoursAndMinutes(new Date(), record.startTime, record.endTime);

      if(hoursMinutes !== 0)
      {
        if (!(hoursMinutes.indexOf(":") === -1)){
          //Hours
          hours = hoursMinutes.split(':')[0];
          // Minutes 
          minutes = hoursMinutes.split(':')[1];

          if(hours !== "" && hours !== "00" && hours > 0)
            totalHours += Number(hours);
          
          if(minutes !== "" && minutes !== "00" && minutes > 0)
            totalMinutes += Number(minutes);
        }
      }
    });

    if(totalHours >= 0 && totalMinutes >= 0)
    {
      //If minutes is more than 60
      if(totalMinutes > 60 && totalMinutes <= 120)
      {
        totalHours += 1;
        totalMinutes -= 60;
      }
      //If minutes is more than 120
      else if(totalMinutes > 120 && totalMinutes <= 180)
      {
        totalHours += 2;
        totalMinutes -= 120;
      }
      //If minutes is more than 180
      else if(totalMinutes > 180 && totalMinutes <= 240)
      {
        totalHours += 3;
        totalMinutes -= 180;
      }
      //If minutes is more than 240
      else if(totalMinutes > 240 && totalMinutes <= 300)
      {
        totalHours += 4;
        totalMinutes -= 240;
      }
      
      totalMinutes = minuteCalculate(totalMinutes);

      if(totalHours >= 0 && totalMinutes === 1)
        return (totalHours + 1).toString();
      else
        return totalHours + "." + totalMinutes;
    }
    else{
      return 0;
    }
  }
  else{
    return 0;
  }
}

//Minutes calculcate formula
export const minuteCalculate = (minutes:number) => {
  var decimalMinutes = 0
  if(minutes)
  {
    if(minutes >= 0 && minutes <= 2)
    {
      decimalMinutes = 0;
    }
    else if(minutes >= 3 && minutes <= 8)
    {
      decimalMinutes = 1;
    }
    else if(minutes >= 9 && minutes <= 14)
    {
      decimalMinutes = 2;
    }
    else if(minutes >= 15 && minutes <= 20)
    {
      decimalMinutes = 3;
    }
    else if(minutes >= 21 && minutes <= 26)
    {
      decimalMinutes = 4;
    }
    else if(minutes >= 27 && minutes <= 32)
    {
      decimalMinutes = 5;
    }
    else if(minutes >= 33 && minutes <= 38)
    {
      decimalMinutes = 6;
    }
    else if(minutes >= 39 && minutes <= 44)
    {
      decimalMinutes = 7;
    }
    else if(minutes >= 45 && minutes <= 50)
    {
      decimalMinutes = 8;
    }
    else if(minutes >= 51 && minutes <= 56)
    {
      decimalMinutes = 9;
    }
    else if(minutes >= 57 && minutes <= 60)
    {
      decimalMinutes = 1;
    }
  }

  return decimalMinutes;
}

