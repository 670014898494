import { GoAFormItem } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { FlightReport } from '../../model/IFlightReport';

interface IViewFlightReportHiringDetailsProps {
    flightReport:FlightReport,
}

const ViewFlightReportHiringDetails: React.FunctionComponent<IViewFlightReportHiringDetailsProps> = ({flightReport,...props}) => {
  return (
    <>
      <div>
        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Vendor">
              {/* <label>{flightReport?.vendor?.name}</label> */}
              <label>{flightReport?.contractRegistration?.name1}</label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Vendor ID">
              {/* <label>{flightReport?.vendor?.financeVendorId}</label> */}
              <label>{flightReport?.contractRegistration?.financeVendorId}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Hiring Start Date">
              <label>
                {flightReport?.contractRegistration ? moment(
                  flightReport?.contractRegistration?.effectiveDate
                ).format("yyyy-MM-DD") : ""}
              </label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Hiring End Date">
              <label>
                {flightReport?.contractRegistration ? moment(
                  flightReport?.contractRegistration?.terminationDate
                ).format("yyyy-MM-DD"): ""}
              </label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Point Of Hire">
              <label>{flightReport?.contractRegistration?.pointOfHire}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Contract Type">
              <label>{flightReport?.contractRegistration?.contractType}</label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Aircraft Type & Size">
              <label>{flightReport?.contractRegistration ? flightReport?.contractRegistration?.aircraftType + " " + flightReport?.contractRegistration?.aircraftSize : ""}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Hiring Remarks">
            <label>{flightReport?.contractRegistration?.remark}</label>
            </GoAFormItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFlightReportHiringDetails;
