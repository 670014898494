import axios from "axios";
import { aviationAppEnv } from "../model/config/IConfigSetting";

//#region "1GX Coding"

// export const httpWildfireFinancial1GX = axios.create({
//   baseURL: "https://wildfire-finance-api-dev.azurewebsites.net/api/",
//   headers: {
//     'Content-Type': 'application/json',
//     'x-functions-key':'9h5ad4sSRENXpBOrjzPkZiPif_IJcyndDYfPZKeiCoi4AzFuH8pqUg=='
//   }
// });

const envFinancial1gx: aviationAppEnv = {
  local: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/wildfire-finance-api-dev/",
  },
  dev: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/wildfire-finance-api-dev/",
  },
  test: {
    baseURL: "https://apim.wildfireapps-dev.alberta.ca/wildfire-finance-api-dev/",
  },
  uat: {
    baseURL: "https://goa-wdp-apim-uat.azure-api.net/wildfire-finance-api-uat/",
  },
  prod: {
    baseURL: "https://goa-wdp-apim.azure-api.net/wildfire-finance-api/",
  }
};

const appEnv = process.env.REACT_APP_ENV as keyof aviationAppEnv;
//const appEnv = "local" as keyof aviationAppEnv;

export const httpFinancial1GXApim= axios.create({
  baseURL: `${envFinancial1gx[appEnv].baseURL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF": "1",
  },
  // headers: {
  //   'Content-Type': 'application/json',
  //   'x-functions-key':'9h5ad4sSRENXpBOrjzPkZiPif_IJcyndDYfPZKeiCoi4AzFuH8pqUg=='
  // },
});

//#endregion