import moment from "moment";
import { CacheLocalStorage } from "../model/search/ICacheLocalStorage";

export const getCacheStorageItem = (cacheType : string) => {
    return localStorage.getItem(cacheType);
}

export const getCacheStorageItemInJSON = (cacheType : string) => {
    if(localStorage.getItem(cacheType))
    {
        const cacheData = getCacheStorageItem(cacheType);

        if(cacheData){
            var cacheLocalStorage = JSON.parse(cacheData);
            return cacheLocalStorage;
        }
        else
            return undefined;
    }
    else
        return undefined;
}

export const createCacheStorageItem = (cacheType : string, tabActiveIndex:number, auth:any) => {
    let primaryCacheLocalStorage = new CacheLocalStorage();
    primaryCacheLocalStorage.id = auth.user?.profile.email;
    primaryCacheLocalStorage.token = auth.user?.access_token;
    primaryCacheLocalStorage.expiry = moment(new Date(), 'yyyy-MM-DD;HH:mm:ss').add('2', 'weeks').toString(); 
    //primaryCacheLocalStorage.expiry = moment(new Date(), 'yyyy-MM-DD;HH:mm:ss').add('10', 'seconds').toString(); 

    primaryCacheLocalStorage.tabIndex = tabActiveIndex;

    updateCacheStorageItem(cacheType, primaryCacheLocalStorage);
}

export const updateCacheStorageItem = (cacheType : string, primaryCacheLocalStorage : CacheLocalStorage) => {
    localStorage.setItem(cacheType, JSON.stringify(primaryCacheLocalStorage));
}

export const removeCacheStorageItem = (cacheType : string) => {
    localStorage.removeItem(cacheType);
}

export const onFilterPropertyChange = (cacheType : string, value: Partial<CacheLocalStorage>) => {
    const cacheData = getCacheStorageItem(cacheType);
    if(cacheData)
      var cacheLocalStorage = JSON.parse(cacheData);
    
    let newValue = new CacheLocalStorage();
    newValue = new CacheLocalStorage({ ...cacheLocalStorage, ...value });

    updateCacheStorageItem(cacheType, newValue);
    return newValue;
}

