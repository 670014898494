// Interface for the JSON structure
export interface IFlightReportNote {
    note: string;
    createTimestamp: string;
    createUserId: string;
    deleted: boolean;
  }
  
  // TypeScript model class implementing the interface
  export class FlightReportNote implements IFlightReportNote {
    note: string;
    createTimestamp: string;
    createUserId: string;
    deleted: boolean;
  
    // Constructor to initialize the properties
    constructor(
      note?: string,
      createTimestamp?: string,
      createUserId?: string,
      deleted?: boolean
    ) {
      this.note = note??="";
      this.createTimestamp = createTimestamp??=new Date().toISOString();
      this.createUserId = createUserId??= "wildfire" || "";
      this.deleted = deleted || false;
    }
  }