import axios from "axios";
import { aviationAppEnv } from "../model/config/IConfigSetting";

//export const httpRulesEngineApimUat= "https://goa-wdp-apim-uat.azure-api.net/rules-engine/";

const envRulesEngine: aviationAppEnv = {
    local: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/rules-engine/api/v1.0/rules-engine/",
    },
    dev: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/rules-engine/api/v1.0/rules-engine/",
    },
    test: {
      baseURL: "https://apim.wildfireapps-dev.alberta.ca/rules-engine/api/v1.0/rules-engine/",
    },
    uat: {
      baseURL: "https://goa-wdp-apim-uat.azure-api.net/rules-engine/api/v1.0/rules-engine/",
    },
    prod: {
      baseURL: "https://goa-wdp-apim.azure-api.net/rules-engine/api/v1.0/rules-engine/",
    }
  };
  
  const appEnv = process.env.REACT_APP_ENV as keyof aviationAppEnv;
  //const appEnv = "uat" as keyof aviationAppEnv;
  
  //export const httpRulesEngineApim = `${envRulesEngine[appEnv].baseURL}`;
  export const httpRulesEngineApim= axios.create({
    baseURL: `${envRulesEngine[appEnv].baseURL}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF": "1",
    },
  });
    