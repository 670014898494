import { httpAviationApim } from "../utils/aviationAPIMConfig";
import { IHiringContract } from "../model/HiringContract/IHiringContract";
import { IActiveContract } from "../model/HiringContract/IActiveContract";
import { IArrayOption } from "../model/IArrayOption";
import { IPaginationResult } from "../model/search/IPaginationResult";
import { ISearch } from "../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
  const params = JSON.stringify({
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
    },
    paginationInfo: {
      perPage: objISearch.pagination?.perPage,
      page: objISearch.pagination?.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-service/hiring/contracts/getByDate",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const getRates = async (IHiringContract: IHiringContract, flightReportDate:string, strToken:string = "") => {
  const params = JSON.stringify({
    aircraftRegistration:IHiringContract.aircraftRegistration,
    reportDate:flightReportDate,
    contractNumber:IHiringContract.contractNumber,
    contractId:IHiringContract.contractId,
    contractType:IHiringContract.contractType
  });

  const data = await httpAviationApim.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-service/hiring/contracts/rates",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const getByName = async (objISearch: ISearch, strToken:string = "") => {
  const params = JSON.stringify({
    search: objISearch.search,
    paginationInfo: {
      perPage: objISearch.pagination?.perPage,
      page: objISearch.pagination?.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-registration/getByName",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const getByDate = async (objISearch: ISearch, strToken:string = "") => {
  const params = JSON.stringify({
    flightReportDate: objISearch.flightReportDate,
    search: objISearch.search,
    paginationInfo: {
      perPage: objISearch.pagination?.perPage,
      page: objISearch.pagination?.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IHiringContract>>(
    "/aircrafts-registration/getByDate",     
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

export const HiringContractService = {
  getSearch,
  getRates,
  getByName,
  getByDate
};


export const bindSelectContractRegistration = (hiringContractResult:IHiringContract[]) : IArrayOption[] => {
    var options = [];
    
    if(hiringContractResult != null)
    {
      for (let i = 0; i < hiringContractResult.length; i++) {
        let obj : IArrayOption = {label: hiringContractResult[i]?.aircraftRegistration, value: hiringContractResult[i]?.id};
        options.push(obj);
      }
    }

    return options;
  }

//Hiring Contract - Contract registration, 
export const getContractRegistration = (hiringContracts: IHiringContract[], contractRegistrationId:number) => {
  if(hiringContracts)
  {    
    var result = hiringContracts.find((value) => value.id === contractRegistrationId);   
    return result;
  }
  else{
    return undefined;
  }
}

export const getFlyingRegistration = (activeContracts: IActiveContract[], flyingRegistrationId:number) => {
  if(activeContracts)
  {    
    var result = activeContracts.find((value) => value.id === flyingRegistrationId);
    return result;
  }
  else{
    return undefined;
  }
}
