import { GoATable, GoAFormItem, GoAButton, GoAInputText } from '@abgov/react-components';
import * as React from 'react';
import PageLoader from '../../components/PageLoader';
import { FlightReport } from '../../model/IFlightReport';
import { USLog } from '../../model/Unserviceable/IUSLog';
import { validateHoursMinutes, validateStartTimeEndTime } from '../../utils/checkInput';
import Select from "react-select";
import moment from 'moment';
import { useAuth } from 'react-oidc-context';

interface IEditUSLogProps {
  flightReport: FlightReport;
  arrUSLogs: USLog[];
  onChange: (value: Array<USLog>) => void;
  onDelete: (value:Array<USLog>,deletedValuesArray:Array<USLog>) => void;
}

const EditUSLog: React.FunctionComponent<IEditUSLogProps> = ({flightReport, arrUSLogs, onChange, onDelete, ...props}) => {
  const auth = useAuth();
  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const [rowsUSLogs, setRowsUSLogs] = React.useState<USLog[]>([]);

  // eslint-disable-next-line
  const [tempRowsUSLogs, setTempRowsUSLogs] = React.useState<USLog[]>([]);

  const reasonOptions = [
    { label: 'Mechanical', value: 'Mechanical' },
    { label: 'Personnel', value: 'Personnel' },
    { label: 'Other', value: 'Other' },
  ];

  React.useEffect(()=>{
    setIsLoading(true);
    
    if(arrUSLogs)
    {
      setRowsUSLogs(p => {return [...arrUSLogs]});
      setTempRowsUSLogs(p => {return [...arrUSLogs]});
    }

    setIsLoading(false);
    
  },[arrUSLogs])

  //on property values change
  function onPropertyChange(idx:number,value: Partial<USLog>) {
    let newValue = new USLog(rowsUSLogs[idx]);

    if(newValue.uSLogId !== "00000000-0000-0000-0000-000000000000")
    {
      newValue.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
      newValue.updateUserId = auth?.user?.profile?.name ?? "wildfire"; 
    }

    newValue =  new USLog({...newValue, ...value});
    let rows = [...rowsUSLogs];
    rows[idx] = newValue;
    setRowsUSLogs(p=> {return rows});

    //send on change to parent component "FlightReportSummary"
    onChange(rows);
  }

  async function handleAddRow(){
    setIsLoading(true);

    const uSLog = new USLog();
    uSLog.flightReportId = flightReport.flightReportId;
    uSLog.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
    uSLog.createUserId = auth?.user?.profile?.name ?? "wildfire";
     
    setRowsUSLogs(p => {return [...rowsUSLogs,uSLog]});

    //onAddNewRow(false);
    setIsLoading(false);
  };

  async function handleDelete(id: string, idx: number) {
    // Create a copy of formValues
    const updatedRowsUSLogs = [...rowsUSLogs];
    let filterRowsUSLogs = [...rowsUSLogs];

    var tobeDeletedIndex;
    // Find the item to be deleted
    if (id !== undefined && id !== "00000000-0000-0000-0000-000000000000")
    {
      tobeDeletedIndex = updatedRowsUSLogs.findIndex(x => x.uSLogId === id);
    }
    else
      tobeDeletedIndex = idx;

    if (tobeDeletedIndex !== -1) {
      // Create a copy of the item to be deleted
      const tobeDeleted = { ...updatedRowsUSLogs[tobeDeletedIndex] };

      // Update the 'deleted' property
      tobeDeleted.deleted = true;

      if(tobeDeleted.startTime === "" && tobeDeleted.endTime === "" && tobeDeleted.reason === "")
      {
        updatedRowsUSLogs.splice(tobeDeletedIndex, 1);
        filterRowsUSLogs.splice(tobeDeletedIndex, 1);
      }
      else{
        // Replace the item in the updatedRowsUSLogs array
        updatedRowsUSLogs[tobeDeletedIndex] = tobeDeleted;
        filterRowsUSLogs[tobeDeletedIndex] = tobeDeleted;

        filterRowsUSLogs = filterRowsUSLogs.filter(x => !x.deleted);
      }

      // Update the state and trigger a re-render
      setRowsUSLogs(filterRowsUSLogs);
      //setRowsUSLogs(updatedRowsUSLogs);

      //send on change to parent component "FlightReportSummary"
      onDelete(updatedRowsUSLogs,filterRowsUSLogs);
    }
  }

  return(
    <>
      <PageLoader visible={loading} />

      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width100">
            <div className="row accordion unserviceableReport">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Unserviceable Log</h3>
                </div>
                <div className="content">
    
                  <div className="unserviceable white-background">
                    <GoATable width='100%'>
                      <thead>
                        <tr>
                          <th className="width185px">Start Time</th>
                          <th className="width215px">End Time</th>
                          <th className="width240px">Reason</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody style={{ position: "sticky", top: 0 }}>
                          {rowsUSLogs || rowsUSLogs !== undefined ?  
                            rowsUSLogs.filter(x=> !x.deleted).map((_record: any, idx: any) => (
                              <tr key={idx}>
                                <td>
                                  <GoAFormItem error={_record?.startTimeError}>
                                    <GoAInputText
                                        name={"txtStartTime"+idx}
                                        value={_record?.startTime}
                                        width="100%"
                                        maxLength={5}
                                        placeholder="hh:mm"
                                        error={_record?.isStartTimeError}
                                        onChange={(name, value) => {
                                        if (value) {
                                          if(validateHoursMinutes(value,2))
                                          {
                                            if(validateStartTimeEndTime(new Date(), value, _record?.endTime))
                                            {
                                              onPropertyChange(idx,{startTime:value, isStartTimeError:false, startTimeError:"", isEndTimeError:false, endTimeError:"",});
                                            }
                                            else{
                                              onPropertyChange(idx,{startTime:value, isStartTimeError:true, startTimeError:"Start Time should be below than End Time"});
                                            }
                                          }
                                          else{
                                            onPropertyChange(idx,{startTime:value, isStartTimeError:true, startTimeError:"hh:mm valid format"});
                                          }
                                        } else {
                                          onPropertyChange(idx,{startTime:null, isStartTimeError:false, startTimeError:""});
                                        }
                                      }}
                                      />
                                    </GoAFormItem>
                                </td>
                                <td>
                                  <GoAFormItem error={_record?.endTimeError}>
                                    <GoAInputText
                                        name={"txtEndTime"+idx}
                                        value={_record?.endTime}
                                        width="100%"
                                        maxLength={5}
                                        placeholder="hh:mm"
                                        error={_record?.isEndTimeError}
                                        onChange={(name, value) => {
                                        if (value) {
                                          if(validateHoursMinutes(value,2))
                                          {
                                            if(validateStartTimeEndTime(new Date(), _record?.startTime, value))
                                            {
                                              onPropertyChange(idx,{endTime:value, isEndTimeError:false, endTimeError:"", isStartTimeError:false, startTimeError:""});
                                            }
                                            else{
                                              onPropertyChange(idx,{startTime:value, isEndTimeError:true, endTimeError:"End Time should be greater than Start Time"});
                                            }
                                          }
                                          else{
                                            onPropertyChange(idx,{endTime:value, isEndTimeError:true, endTimeError:"hh:mm valid format"});
                                          }
                                        } 
                                        else {
                                          onPropertyChange(idx,{endTime:null, isEndTimeError:false, endTimeError:""});
                                        }
                                      }}
                                      />
                                    </GoAFormItem>
                                </td>
                                <td>
                                    <Select
                                      name={"selReason"+idx}
                                      options={ reasonOptions }
                                      className="width100"
                                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                      menuPortalTarget={document.body}
                                      value={reasonOptions.find(t => t.value === _record.reason)}
                                      onChange={async (value:any) =>{
                                        if(value.value)
                                        {
                                          onPropertyChange(idx,{reason:value.value});
                                        }
                                      }}
                                      isSearchable={true}
                                    />
                                </td> 
                                <td>
                                  <GoAButton
                                    type="tertiary" leadingIcon="trash"
                                    onClick={() => handleDelete(_record.uSLogId, idx)}>
                                  </GoAButton>
                                </td>
                              </tr>
                          )) : 
                          <></>
                          }
                        </tbody>
                    </GoATable>
                  </div>

                  <div className="unserviceable flying-hours">
                    <GoAButton type="secondary" trailingIcon="add"
                      onClick={() => handleAddRow()}>
                      Add new
                    </GoAButton>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EditUSLog;
