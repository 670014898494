import { GoAFormItem, GoAInputDate, GoAInputText } from '@abgov/react-components';
import * as React from 'react';
import { FlightReport } from '../../model/IFlightReport';
import { USStandToStandDown } from '../../model/Unserviceable/IUSStandToStandDown';
import { validateHoursMinutes, validateStartTimeEndTime } from '../../utils/checkInput';

interface IEditStandToStandDownTimeProps {
  flightReport: FlightReport;
  uSStandToStandDown: USStandToStandDown | undefined;
  onChange: (value: USStandToStandDown) => void;
}

const EditStandToStandDownTime: React.FunctionComponent<IEditStandToStandDownTimeProps> = ({flightReport, uSStandToStandDown, onChange, ...props}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formValues, setFormValues] = React.useState(new USStandToStandDown(uSStandToStandDown));

  //Stand To
  const [isStandToError, setIsStandToError] = React.useState(false);
  const [standToError, setStandToError] = React.useState("");

  //Stand Down
  const [isStandDownError, setIsStandDownError] = React.useState(false);
  const [standDownError, setStandDownError] = React.useState("");

  //on property values change
  function onPropertyChange(value: Partial<USStandToStandDown>) {
    let newValue = new USStandToStandDown();
    newValue = new USStandToStandDown({ ...uSStandToStandDown, ...value });

    setFormValues(newValue);

    //send on change to parent component 'FlightReportSummary'
    onChange(newValue);
  }

  return(
    <>
      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width100">
            <div className="row accordion unserviceableReport">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Stand To / Stand Down Time</h3>
                </div>
                <div className="content standToStandDown">
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Unserviceable Date">
                    <GoAInputDate
                        name="dtUnserviceableDate"
                        placeholder="YYYY-MM-DD"
                        value={uSStandToStandDown?.unserviceableDate && uSStandToStandDown?.unserviceableDate !== "" ? uSStandToStandDown?.unserviceableDate : flightReport?.flightReportDate}
                        min={new Date(1950, 1, 1)}
                        max={new Date()}
                        width="100%"
                        disabled={true}
                        onChange={(name, value) => {
                        }}                      
                      />
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Stand To" error={standToError}>
                    <GoAInputText
                        name="txtUnserviceableStandTo"
                        value={uSStandToStandDown?.standTo && uSStandToStandDown?.standTo !== "" ? uSStandToStandDown?.standTo : ""}
                        width="100%"
                        maxLength={5}
                        placeholder="hh:mm"
                        error={isStandToError}
                        onChange={(name, value) => {
                          if (value) {
                            if(validateHoursMinutes(value,2))
                            {
                              if(validateStartTimeEndTime(uSStandToStandDown?.unserviceableDate, value, uSStandToStandDown?.standDown))
                              {
                                setIsStandToError(false);
                                setStandToError("");
                                
                                setIsStandDownError(false);
                                setStandDownError("");

                                onPropertyChange({ standTo: value });
                              }
                              else{
                                setIsStandToError(true);
                                setStandToError("Stand To should be below than Stand Down time");
                                onPropertyChange({ standTo: value });
                              }
                            }
                            else{
                              setIsStandToError(true);
                              setStandToError("hh:mm valid format");
                              onPropertyChange({ standTo: value });
                            }
                          } 
                          else {
                            setIsStandToError(true);
                            setStandToError("Required to save");
                            onPropertyChange({ standTo: undefined });
                          }
                        }}
                      />
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Stand Down" error={standDownError}>
                      <GoAInputText
                        name="txtUnserviceableStandDown"
                        value={uSStandToStandDown?.standDown && uSStandToStandDown?.standDown !== "" ? uSStandToStandDown?.standDown : ""}
                        width="100%"
                        maxLength={5}
                        placeholder="hh:mm"
                        error={isStandDownError}
                        onChange={(name, value) => {
                          if (value) {
                            if(validateHoursMinutes(value,2))
                            {
                              if(validateStartTimeEndTime(uSStandToStandDown?.unserviceableDate,uSStandToStandDown?.standTo,value))
                              {
                                setIsStandDownError(false);
                                setStandDownError("");

                                setIsStandToError(false);
                                setStandToError("");

                                onPropertyChange({ standDown: value });
                              }
                              else{
                                setIsStandDownError(true);
                                setStandDownError("Stand Down should be greater than Stand To time");
                                onPropertyChange({ standDown: value });
                              }
                            }
                            else{
                              setIsStandDownError(true);
                              setStandDownError("hh:mm valid format");
                              onPropertyChange({ standDown: value });
                            }
                          } else {
                            setIsStandDownError(true);
                            setStandDownError("Required to save");
                            onPropertyChange({ standDown: undefined });
                          }
                        }}
                      />
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Total Hours">
                      <GoAInputText
                        name="txtUnserviceableTotalHours"
                        value={uSStandToStandDown?.totalHours && uSStandToStandDown?.totalHours !== "" ? uSStandToStandDown?.totalHours : ""}
                        width="100%"
                        maxLength={10}
                        disabled={true}
                        onChange={(name: any, value: any) => {
                        }}
                      />
                    </GoAFormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default EditStandToStandDownTime;
