import { httpDomainServiceApim } from "../../utils/domainServiceAPIMConfig";
import { ICostingActivity } from "../../model/domainServices/ICostingActivity";
import { IArrayOption } from "../../model/IArrayOption";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
    const params = JSON.stringify({
      search: objISearch.search,
      
      paginationInfo: {
        perPage: objISearch.pagination?.perPage,
        page: objISearch.pagination?.page,
      },
    });
  
    const data = await httpDomainServiceApim.post<IPaginationResult<ICostingActivity>>(
      "/costing_activity/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<ICostingActivity>(
      `/costing_activity/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };
  
  export const CostingActivityService = {
    getSearch,
    getById,
  };

  export const getCostingActivity = (costingActivityStateResult:IPaginationResult<ICostingActivity>, costingActivityId:string) => {
    if(costingActivityStateResult != null)
    {
      const result = costingActivityStateResult.data.find(value => value.costingActivityId === costingActivityId);
      return result;
    }
    else{
      return undefined;
    }
  }

  export const bindCostingActivityDropdown = (CostingActivityPaginationResult:IPaginationResult<ICostingActivity>): IArrayOption[] => {
    const options = [];
    
    if(CostingActivityPaginationResult != null)
    {
      for (let i = 0; i < CostingActivityPaginationResult.data.length; i++) {
        let obj: IArrayOption = {label: CostingActivityPaginationResult.data[i].code + " - " + CostingActivityPaginationResult.data[i].description, code:CostingActivityPaginationResult.data[i].code ?? "", value: CostingActivityPaginationResult.data[i].costingActivityId};
        options.push(obj);
      }
    }

    return options;
  }