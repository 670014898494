import {
  GoAAppHeader
} from "@abgov/react-components";
import * as React from "react";

interface IAppHeaderProps {}

const AppHeader: React.FunctionComponent<IAppHeaderProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [url, setUrl] = React.useState("/");
  
  // React.useEffect(() => {
  //   if(sessionStorage.getItem("username") != null && sessionStorage.getItem("username") !== "")
  //   {
  //     //navigate("/dashboard");
  //   }
  //   else{
  //     //setUrl("/");
  //   }
  // }, [navigate]);
  
  // function onChange(name: string, value: string | string[]) {
  //   setValue(userInfo);
  // }

  return (
    <>
        <div>
          <GoAAppHeader url={url} heading="Aviation reporting" >
            <div>
              <nav>
                {/* <NavLink to="/">Home</NavLink> */}
                {/* <NavLink to="/test">Test</NavLink> */}
                {/* <NavLink to="/domainTables">Domain Tables</NavLink>
            <NavLink to="/rateType">Rate type</NavLink> */}
              </nav>
              {/* <div>
                <GoADropdown
                  name="colors"
                  value={userInfo}
                  onChange={onChange}
                  leadingIcon="person-circle"
                >
                  <GoADropdownItem value="Username" label="Username" />
                  <GoADropdownItem value="Logout" label="Logout" />
                </GoADropdown>
              </div> */}
            </div>
          </GoAAppHeader>
          </div>
    </>
  );
};

export default AppHeader;
