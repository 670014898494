import { calculateCost } from "../utils/commonMethods";
import { IArrayOption } from "./IArrayOption";

interface IFlightOtherExpenses {
  flightOtherExpensesId: string,
  provider: string;

  litres: string;
  isLitresError:boolean;
  litresError: string;

  doubleCrew: boolean;
  createTimestamp: string | null;
  createUserId: string,
  updateTimestamp : string | null;
  updateUserId: string,
  deleted: boolean
}

interface IFlightCrewEligibility {
  flightCrewEligibilityId: string;
  flightReportId:number | undefined;
  name: string;
  role: string;
  breakfast: string;
  lunch: string;
  dinner: string;
  lodging: string;
  vehicle: string;
  createTimestamp: string | null;
  createUserId: string | undefined;
  updateTimestamp?: string | null;
  updateUserId: string;
  deleted: boolean;
}

interface IExpenseDetail {
  flightReportId: number;
  expenseId: string;
  fireNumberId?: number;
  crewTypeOnBoardId: string;
  costingActivityId: string;
  rateTypeId: string;
  rateType:string|undefined;

  noOfUnits: number;
  
  ratePerUnit: number ;
  isRatePerUnitError?:boolean;
  ratePerUnitError:string;

  rateUnitId: string|undefined;
  cost: any;
  internalOrder: string;
  costCenter: string;
  fund: string;
  account: string;
  expenseType: string;
  fireNumber: string;
  fireYear?:number;

  costingActivityCode: string;
  rateUnitList?: IArrayOption[];
  costingActivityList?: IArrayOption[];
  rateTypeList?: IArrayOption[];
  createTimestamp: string | null;
  createUserId?: string;
  fireNumberList?: IArrayOption[];
  deleted: boolean;

  invoiceId?:any, 
  invoiceNumber?:string,
}

export interface IFlightExpenses {
  contractType:string;
  flightOtherExpenses: IFlightOtherExpenses;
  flightCrewEligibility: IFlightCrewEligibility[];
  expenseDetails: IExpenseDetail[];
}



export class FlightOtherExpenses implements IFlightOtherExpenses {
  constructor(from?: Partial<IFlightOtherExpenses>) {
    this.flightOtherExpensesId = from?.flightOtherExpensesId ?? "00000000-0000-0000-0000-000000000000";
    this.provider = this.provider = from?.provider?.trim() || 'GOA Provided';

    this.litres = from?.litres ?? '0';
    this.isLitresError = from?.isLitresError ?? false;
    this.litresError = from?.litresError ?? "";
    
    this.doubleCrew = from?.doubleCrew ?? false;
    this.createTimestamp = from?.createTimestamp ?? null;
    this.createUserId = from?.createUserId ?? '';
    this.updateUserId = from?.updateUserId ?? '';
    this.updateTimestamp = from?.updateTimestamp ?? null;
  }
  deleted!: boolean;
  flightOtherExpensesId: string = '';
  provider: string = '';

  litres: string = '0';
  isLitresError:boolean;
  litresError: string;

  doubleCrew: boolean = false;
  createTimestamp:  string | null;
  createUserId: string = "";
  updateTimestamp: string | null;
  updateUserId: string = "";
}

export class FlightCrewEligibility implements IFlightCrewEligibility {
  flightCrewEligibilityId: string;
  flightReportId: number | undefined;
  name: string = '';
  role: string | string = '';
  breakfast: string = '';
  lunch: string = '';
  dinner: string = '';
  lodging: string = '';
  vehicle: string = '';
  createTimestamp: string | null;
  createUserId: string | undefined;
  updateTimestamp?: string | null;
  updateUserId: string;
  deleted: boolean;
  constructor(from?: Partial<FlightCrewEligibility>) {
    this.flightCrewEligibilityId = from?.flightCrewEligibilityId ?? "00000000-0000-0000-0000-000000000000";
    this.flightReportId = from?.flightReportId || 0;
    this.name = from?.name || '';
    this.role = from?.role || 'Pilot';
    this.breakfast = from?.breakfast || 'GOA Provided';
    this.lunch = from?.lunch || 'GOA Provided';
    this.dinner = from?.dinner || 'GOA Provided';
    this.lodging = from?.lodging || 'GOA Provided';
    this.vehicle = from?.vehicle || 'GOA Provided';
    this.createTimestamp = from?.createTimestamp ?? null;
    this.createUserId = from?.createUserId ?? 'missing';
    this.updateTimestamp = null;
    this.updateUserId = from?.updateUserId ?? '';
    this.deleted = from?.deleted ?? false;
  }
}

export class ExpenseDetail implements IExpenseDetail {
  flightReportId: number = 0;
  expenseId: string = '';
  fireNumberId?: number = 0;
  fireNumberList?: IArrayOption[];
  
  crewTypeOnBoardId: string = '';
  costingActivityId: string = '';
  costingActivityList?: IArrayOption[];

  rateTypeId: string= '';
  rateType:string|undefined='';
  rateTypeList?: IArrayOption[];

  noOfUnits: number = 0;

  ratePerUnit: number = 0;
  isRatePerUnitError?:boolean;
  ratePerUnitError:string;

  rateUnitId: string |undefined  = '';
  rateUnitList?: IArrayOption[];

  cost: any = 0;

  internalOrder: string = '';
  internalOrderList?: IArrayOption[];
  costCenter: string = '';
  costCenterList?:IArrayOption[];
  fund: string = '';
  fundList?:IArrayOption[];
  account: string = '';
  accountList?:IArrayOption[];

  expenseType: string = '';
  fireNumber: string = '';
  fireYear?:number;

  costingActivityCode: string = '';
  
  createTimestamp: string | null;
  createUserId?: string = '';
  updateTimestamp?: string | null;

  deleted: boolean;

  invoiceId?:any; 
  invoiceNumber?:string;

  constructor(from?: Partial<ExpenseDetail>) {
    this.flightReportId = from?.flightReportId || 0;
    this.expenseId = from?.expenseId ??  "00000000-0000-0000-0000-000000000000";
    this.fireNumberId = from?.fireNumberId || 0;
    this.crewTypeOnBoardId = from?.crewTypeOnBoardId || '';

    this.costingActivityId = from?.costingActivityId || "00000000-0000-0000-0000-000000000000";
    this.costingActivityList = from?.costingActivityList || [];

    this.rateTypeId = from?.rateTypeId || '';
    this.rateType=from?.rateType||'';
    this.rateTypeList = from?.rateTypeList || [];

    this.noOfUnits = from?.noOfUnits || 0;
    
    this.ratePerUnit = from?.ratePerUnit || 0;
    this.isRatePerUnitError = from?.isRatePerUnitError ?? false;
    this.ratePerUnitError = from?.ratePerUnitError ?? "";

    this.rateUnitId = from?.rateUnitId || '';
    this.rateUnitList = from?.rateUnitList || [];

    this.cost = calculateCost(from?.noOfUnits,from?.ratePerUnit);

    this.internalOrder = from?.internalOrder || '';
    this.internalOrderList = from?.internalOrderList ?? undefined;
    this.costCenter = from?.costCenter || '';
    this.costCenterList = from?.costCenterList ?? undefined;
    this.fund = from?.fund || '';
    this.fundList = from?.fundList ?? undefined;
    this.account = from?.account || '';
    this.accountList = from?.accountList ?? undefined;

    this.expenseType = from?.expenseType || '';

    this.fireNumber = from?.fireNumber || '';
    this.fireYear = from?.fireYear;
    this.fireNumberList = from?.fireNumberList || [];
    
    this.costingActivityCode = from?.costingActivityCode || '';
    
    this.createTimestamp = from?.createTimestamp || null;
    this.createUserId = from?.createUserId || '';
    this.updateTimestamp = from?.updateTimestamp || null;
    
    this.deleted = from?.deleted ?? false;

    this.invoiceId = from?.invoiceId ?? null;
    this.invoiceNumber = from?.invoiceNumber ?? "";

  }
}

export class FlightExpenses implements IFlightExpenses {
  constructor(from?: Partial<IFlightExpenses>) {
    this.contractType=from?.contractType ?? "";
    this.flightOtherExpenses =new FlightOtherExpenses(from?.flightOtherExpenses);
    this.flightCrewEligibility = from?.flightCrewEligibility ?? [new FlightCrewEligibility()];
    this.expenseDetails = from?.expenseDetails ?? [new ExpenseDetail()];

  }
  contractType:string;
  flightOtherExpenses: FlightOtherExpenses = new FlightOtherExpenses();
  flightCrewEligibility: FlightCrewEligibility[] = [new FlightCrewEligibility()];
  expenseDetails: ExpenseDetail[] = [new ExpenseDetail()];
}


