import { GoAButton, GoAIcon } from '@abgov/react-components';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import PageLoader from '../../components/PageLoader';
import { FlightReport } from '../../model/IFlightReport';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import { AuthenticationService } from '../../services/authentication/AuthenticationService';

interface IFlightReportFlaggedProps {
    flightReport:FlightReport,
    onFlagChange: (value: FlightReport) => void;
}

const FlightReportFlagged: React.FunctionComponent<IFlightReportFlaggedProps> = ({flightReport,onFlagChange,...props}) => {
  const auth = useAuth(); 
  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const theme = flightReport.isFlagged ? "filled" : "outline";

  //on property values change
  async function onFlagStatusChange(record:FlightReport, isFlagged:boolean) {
    if (record.flightReportId) {
      setIsLoading(true);

      record.isFlagged = isFlagged;

      //Update flag status
      await FlightReportCRUD.updateFlagged(record, auth.user?.access_token as string);
      //Update to parent component "FlightReportDetailHeader"
      onFlagChange(record);

      setIsLoading(false);
    }
  }

  return(
    <>
      <PageLoader visible={loading} />
      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
        <GoAButton size="compact" type="tertiary" onClick={() => {
            onFlagStatusChange(flightReport,flightReport.isFlagged ? false : true);
          }}>
            <GoAIcon type="flag" theme={ theme } />
        </GoAButton>
      </div>}
    </>
  );
};

export default FlightReportFlagged;
