import { GoAFormItem } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { USStandToStandDown } from '../../model/Unserviceable/IUSStandToStandDown';

interface IViewStandToStandDownTimeProps {
  uSStandToStandDown : USStandToStandDown | undefined;
}

const ViewStandToStandDownTime: React.FunctionComponent<IViewStandToStandDownTimeProps> = ({uSStandToStandDown,...props}) => {
  return(
    <>
      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width100">
            <div className="row accordion unserviceableReport">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Stand To / Stand Down Time</h3>
                </div>
                <div className="content standToStandDown">
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Unserviceable Date">
                      <label>
                        {uSStandToStandDown?.unserviceableDate && uSStandToStandDown?.unserviceableDate !== "" ? moment(uSStandToStandDown?.unserviceableDate).format("yyyy-MM-DD") : ""}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Stand To">
                      <label>
                        {uSStandToStandDown?.standTo && uSStandToStandDown?.standTo !== "" ? moment(uSStandToStandDown?.standTo, 'HH:mm:ss').format('HH:mm') : ""}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Stand Down">
                      <label>
                      {uSStandToStandDown?.standDown && uSStandToStandDown?.standDown !== "" ? moment(uSStandToStandDown?.standDown, 'HH:mm:ss').format('HH:mm') : ""}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className='width25 col-md-6'>
                    <GoAFormItem label="Total Hours">
                      <label>
                        {uSStandToStandDown?.totalHours}
                      </label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default ViewStandToStandDownTime;
