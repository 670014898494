import {
  GoAButton,
  GoAFormItem,
  GoAInput,
  GoAInputDate,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import { ICorporateRegion, IForestArea } from "../../model/ICorporateRegion";
import { IFilter } from "../../model/search/IFilter";
import { IPagination } from "../../model/search/IPagination";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";
import {
  bindMutltiSelectDropdown,
  CorporateRegionDomainService,
} from "../../services/domainServices/CorporateRegionDomainService";

import { addDays } from "../../utils/dateHandler";
import { MultiSelect } from "react-multi-select-component";
import { Tab, Tabs } from "../../components/Tabs";
import FlightReportRequireActionList from "./FlightReportRequireActionList";
import FlightReportAll from "./FlightReportAll";
import { useAuth } from "react-oidc-context";
import { AuthenticationService } from "../../services/authentication/AuthenticationService";
import { FlightReportDashboardService } from "../../services/flightReportDashboard/FlightReportDashboardService";
import CacheType from "../../model/enum/CacheType";
import { createCacheStorageItem, getCacheStorageItem, onFilterPropertyChange, removeCacheStorageItem } from "../../utils/cacheMethods";
import { CacheLocalStorage } from "../../model/search/ICacheLocalStorage";

interface IFlightReportListProps {}

const FlightReportList: React.FunctionComponent<IFlightReportListProps> = (
  props
) => {
  const auth = useAuth();
  
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading] = React.useState(false);
  //Corporate region
  const [corporateRegionPaginationResult, setCorporateRegionPaginationResult] = React.useState<IPaginationResult<ICorporateRegion>>();
  //Search
  const [searchValue, setSearchValue] = React.useState("");
  
   // eslint-disable-next-line
  const [searchInputKeyPress, setSearchInputKeyPress] = React.useState("");

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  //Filter date
  const [startDate, setStartDate] = React.useState("");
  const [startDateError, setStartDateError] = React.useState(false);
  const [endDate, setEndDate] = React.useState("");
  const [endDateError, setEndDateError] = React.useState(false);
  const [maxDate] = React.useState(moment(new Date()).format("yyyy-MM-DD"));
  const [startMaxDate] = React.useState(moment(new Date()).format("yyyy-MM-DD"));
  const [minEndDate, setMinEndDate] = React.useState(new Date(1950, 1, 1));
  const [showEndDate, setShowEndDate] = React.useState(true);

  //Forest area (Corporate region)
  const [forestAreaSelected, setForestAreaSelected] = React.useState([]);
  const [forestAreaOptions, setForestAreaOptions] = React.useState([]);

  //Status
  const [statusCountOptions, setStatusCountOptions] = React.useState([]);
  const [statusCountSelected, setStatusCountSelected] = React.useState([]);

  const [tabActiveIndex,setTabActiveIndex] = React.useState(0);

  //Sorting
  const [sortColumn, setSortColumn] = React.useState("flightReportDate");
  const [sortDirection, setSortDirection] = React.useState(-1);

  // page number
  const [pageNumber, setPageNumber] = React.useState(1);
  //count per page
  const [perPageCount, setPerPageCount] = React.useState(10);

  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isRequireRerender, setIsRequireRerender] = React.useState(false);

  //onKeyPress handler function
  // const keyPressHandler = (
  //   event: React.KeyboardEvent<HTMLInputElement> | any
  // ) => {
  //   //Do something you like with "event"
  //   setSearchInputKeyPress("");
  //   setSearchInputKeyPress(event.key);
  // };

  React.useEffect(() => {
    //const element = document.getElementsByName("flightReportSearch");
    //element[0].addEventListener("keypress", keyPressHandler);

    //Caching & re-applying filter, sorts, pagination, and tabs 
    cachingFilters();

    setTimeout(async () => { await getCorporateRegions(); }, 0);
    setTimeout(async () => { await getStatusCounts(); }, 0);

    setIsDataLoaded(true);
    // eslint-disable-next-line
  }, [tabActiveIndex]);

  async function cachingFilters()
  {
    //Caching & re-applying filter, sorts, pagination, and tabs 
    if(localStorage.getItem(CacheType.aviation_cache))
    {
      const cacheData = getCacheStorageItem(CacheType.aviation_cache);

      if(cacheData){
        var cacheLocalStorage = JSON.parse(cacheData);
        var newDate = new Date().toString();

        if(moment(cacheLocalStorage.expiry, 'YYYY-MM-DD').isBefore(moment(newDate, 'YYYY-MM-DD')))
        {
          removeCacheStorageItem(CacheType.aviation_cache);
          createCacheStorageItem(CacheType.aviation_cache, tabActiveIndex, auth);
        }
        else{
          if(cacheLocalStorage.forestArea)
            setForestAreaSelected(cacheLocalStorage.forestArea);

          if(cacheLocalStorage.status)
            setStatusCountSelected(cacheLocalStorage.status);
          
          setStartDate(cacheLocalStorage.startDate);
          setEndDate(cacheLocalStorage.endDate);

          setSearchValue(cacheLocalStorage.search);

          setTabActiveIndex(cacheLocalStorage.tabIndex);

          setSortColumn(cacheLocalStorage.sortColumn);
          setSortDirection(cacheLocalStorage.sortDirection);

          setPageNumber(cacheLocalStorage.pageNumber);
          setPerPageCount(cacheLocalStorage.perPageCount);
        }
      }
    }
    else{
      if(!auth.isLoading)
        clearAllValues();

        createCacheStorageItem(CacheType.aviation_cache, tabActiveIndex, auth);
    }
  }

  async function clearAllValues()
  {
    setForestAreaSelected([]);
    setStatusCountSelected([]);
    setStartDate("");
    setEndDate("");
    setSearchValue("");
    setTabActiveIndex(0);
    setSortColumn("flightReportDate");
    setSortDirection(-1);
    setPageNumber(1);
    setPerPageCount(10);
    setIsRequireRerender(false);
  }

  async function removeCachingFilters()
  {
    localStorage.removeItem(CacheType.aviation_cache);

    if(tabActiveIndex === 0)
    {
      clearAllValues();
      window.location.reload();
    }
    else
      setTabActiveIndex(0);
  }

  function searchFlightReports(searchParamValue: string) {
    if (searchInputKeyPress === "Enter") {
      return;
    }

    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setSearchValue(searchParamValue);
      setIsRequireRerender(true);

      onFilterPropertyChange(CacheType.aviation_cache, { search: searchParamValue });
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
      setIsRequireRerender(true);

      onFilterPropertyChange(CacheType.aviation_cache, { search: "" });
    }
  }

  //Corporate region
  async function getCorporateRegions() {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "corporateRegionTypeId", 
      columnValue: "4",
    };

    let objISearch: ISearch = {
      search: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    await CorporateRegionDomainService.getSearch(objISearch, auth.user?.access_token as string)
      .then((response: any) => {
        if(response.data)
        {
          setCorporateRegionPaginationResult(response.data);
          const forestAreaResult = bindMutltiSelectDropdown(response.data) as [];
          setForestAreaOptions(forestAreaResult);
        }
      })
      .catch((e: Error) => {
        console.log("Error", e);
      });
  }

  //Status count
  async function getStatusCounts() {

    let strSearchValue = searchValue.toLowerCase();
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegions:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: "",
      sortOrder: "",
      filterBy: objIFilter,
    };

    await FlightReportDashboardService.getStatusCount(objISearch, auth.user?.access_token as string)
      .then((response: any) => {
        if(response)
        {
          setStatusCountOptions(response);
        }
      })
      .catch((e: Error) => {
        console.log("Error", e);
      });
  }

  //#endregion
  function onClickFlightReport(flightReportId?: number) {
    if (flightReportId) {
      navigate(`/flightReportDetail/${flightReportId}`, {
        state: corporateRegionPaginationResult,
      });
    } else {
      navigate(`/flightReportDetail/new`, {
        state: corporateRegionPaginationResult,
      });
    }
  }

  function onChangeSortAndPagination(value: CacheLocalStorage)
  {
    if (value != null && value !== undefined) {
      if(value.sortColumn)
        setSortColumn(value.sortColumn);

      if(value.sortDirection)
        setSortDirection(value.sortDirection);

      if(value.pageNumber)
        setPageNumber(value.pageNumber);

      if(value.perPageCount)
        setPerPageCount(value.perPageCount);
    }
  }

  return (
    <>
    
      <PageLoader visible={loading && auth.isLoading} />
  
      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_R',auth.user?.access_token as string) && <div>

        <div className="contentPadding">
          <div className="left width100">
            <div className="left width-auto">
              <PageHeader title="Flight Report Dashboard" />
            </div>
            <div className="right width-auto header-button">
            
            {AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) &&
              <GoAButton type="primary" onClick={() => onClickFlightReport()}>
                Create new +
              </GoAButton>
            } 
            </div>
          </div>
  
          <div className="header-content">
            <div className="filter-content contentInline ">
              <div className="left width20 paddingRight20px">
                <GoAFormItem label="Forest Area">
                  <MultiSelect
                    options={forestAreaOptions}
                    value={forestAreaSelected}
                    onChange={(param: any) => {
                      setForestAreaSelected(param);
                      setIsRequireRerender(true);
                      onFilterPropertyChange(CacheType.aviation_cache, { forestArea: param });
                    }}
                    labelledBy="--Select--"
                  />
                </GoAFormItem>
              </div>
              <div className="left width35">
                <div className="left width33">
                  <GoAFormItem label="Start Date">
                    <GoAInputDate
                      name="startDate"
                      placeholder="YYYY-MM-DD"
                      value={startDate}
                      min={new Date(1950, 1, 1)}
                      max={startMaxDate}
                      width="100%"
                      // trailingIcon="close"
                      // onTrailingIconClick={() => alert('clicked')}
                      error={startDateError}
                      onChange={(name, value) => {
                        let dtResult = moment(value).format("yyyy-MM-DD");
                        let splitDate = dtResult.split("-");

                        if (dtResult !== "Invalid date") {
                          if (parseInt(splitDate[0]) < 1950) {
                            setStartDateError(true);
                            endDate !== "" ? setEndDate(endDate) : setEndDate("");
                            setShowEndDate(true);
                          } else if (parseInt(splitDate[2]) > 31) {
                            setStartDateError(true);
                            endDate !== "" ? setEndDate(endDate) : setEndDate("");
                            setShowEndDate(true);
                          } else {
                            setStartDateError(false);
                            setStartDate(dtResult as string);
                            let dtObj = addDays(new Date(value), 1);
                            setMinEndDate(dtObj);
                            setShowEndDate(false);
                            setIsRequireRerender(true);

                            if(endDate && value < endDate)
                            {
                              setEndDateError(false);
                            }
                            else{
                              setEndDateError(true);
                            }

                            onFilterPropertyChange(CacheType.aviation_cache, { startDate: dtResult });
                          }
                        } else {
                          setStartDate("");
                          setEndDate("");
                          setEndDateError(false);
                          setMinEndDate(new Date(1950, 1, 1));
                          setIsRequireRerender(true);
                          onFilterPropertyChange(CacheType.aviation_cache, { startDate: "", endDate: "" });
                        }
                      }}
                    />
                  </GoAFormItem>
                </div>

                <div className="left width33 paddingLeft20px">
                  <GoAFormItem label="End Date">
                    <GoAInputDate
                      name="filterEndDate"
                      placeholder="YYYY-MM-DD"
                      value={endDate}
                      min={new Date(1950, 1, 1)}
                      max={maxDate}
                      width="100%"
                      error={endDateError}
                      disabled={startDate === "" ? showEndDate : false}
                      onChange={(name, value) => {
                        let dtResult = moment(value).format("yyyy-MM-DD");
                        let splitDate = dtResult.split("-");
  
                        if (dtResult !== "Invalid date") {
                          if (parseInt(splitDate[0]) < 1950) {
                            setEndDateError(true);
                          } else if (parseInt(splitDate[2]) > 31) {
                            setEndDateError(true);
                          } else if (value < minEndDate) {
                            setEndDateError(true);
                          } else {
                            setMinEndDate(new Date(startDate));
                            setEndDateError(false);
                            if(value >= minEndDate)
                            {
                              setEndDate(value as string);
                            }else{
                              setEndDateError(true);
                            } 

                            if(startDate && value > startDate)
                            {
                              setEndDateError(true);
                            }
                            
                            setIsRequireRerender(true);

                            onFilterPropertyChange(CacheType.aviation_cache, { endDate: dtResult });
                          }
                        } else {
                          setEndDate("");
                          if(startDate && startDate !== "")
                          {
                            setEndDateError(true);
                          }
                          else{
                            setEndDateError(false);
                          }
                          
                          setMinEndDate(new Date(1950, 1, 1));
                          setIsRequireRerender(true);
                          
                          onFilterPropertyChange(CacheType.aviation_cache, { endDate: "" });
                        }
                      }}
                    />
                  </GoAFormItem>
                </div>

                <div className="left width33 paddingLeft20px">
                  <GoAFormItem label="Status">
                    <MultiSelect
                      options={statusCountOptions}
                      value={statusCountSelected}
                      onChange={(param: any) => {
                        setStatusCountSelected(param);
                        setIsRequireRerender(true);

                        onFilterPropertyChange(CacheType.aviation_cache, { status: param });
                      }}
                      labelledBy="--Select--"
                    />
                  </GoAFormItem>
                </div>

              </div>
  
              <div className="right width45 paddingLeft20px">
                <GoAFormItem label="Search">
                  <GoAInput
                    trailingIcon="search"
                    name="flightReportSearch"
                    placeholder="Search by contract registration, vendor, AO02 # or flight report #"
                    onChange={(name, value) => searchFlightReports(value)}
                    onTrailingIconClick={() => searchFlightReports(searchValue)}
                    value={searchValue}
                    width="100%"
                  />
                </GoAFormItem>
              </div>
            </div>
          </div>

          <div className="right">
            <GoAButton
              size="compact"
              type="tertiary"
              onClick={() => {
                removeCachingFilters();
              }}
            >
              Clear all
            </GoAButton>
          </div>
  
          <div className="width100">
            <Tabs activeIndex={tabActiveIndex} data-testid="approvals-tabs" 
              changeTabCallback={(index) => {
                setTabActiveIndex(index);
                onFilterPropertyChange(CacheType.aviation_cache, { tabIndex: index, sortColumn:"flightReportDate", sortDirection:-1, perPageCount:10, pageNumber:1 });
                setIsDataLoaded(false);
              }}>
              <Tab label="Requires Action" data-testid="approvals-required-tab">
                {isDataLoaded ? 
                  <FlightReportRequireActionList
                    forestAreaSelected={forestAreaSelected}
                    startDate={startDate}
                    endDate={endDate}
                    statusCountSelected={statusCountSelected}
                    searchValue={searchValue}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    perPageCount={perPageCount}
                    pageNumber={pageNumber}
                    isRequireRerender={isRequireRerender}
                    onClickFlightReport={onClickFlightReport}
                    onChangeSortAndPagination={onChangeSortAndPagination}
                  />
                : <></>}
              </Tab>
              <Tab label="All Reports" data-testid="approvals-need-attentions-tab" >
                {isDataLoaded ? 
                  <FlightReportAll
                    forestAreaSelected={forestAreaSelected}
                    startDate={startDate}
                    endDate={endDate}
                    statusCountSelected={statusCountSelected}
                    searchValue={searchValue}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    perPageCount={perPageCount}
                    pageNumber={pageNumber}
                    isRequireRerender={isRequireRerender}
                    onClickFlightReport={onClickFlightReport}
                    onChangeSortAndPagination={onChangeSortAndPagination}
                  />
                : <></>}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
                    
      }

      {!(auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_R',auth.user?.access_token as string)) && 
        <div className="contentPadding">
          <div className="left width100">
            <div className="left width-auto">
              <PageHeader title="Flight Report Dashboard" />
            
              <div className="header-content">
                <p>You dont have permission</p>
              </div>
          </div>
        </div>
        </div>
      }
    </>
  );
};

export default FlightReportList;
