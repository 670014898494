import moment from "moment";

export type Validator = (input: any) => string;

export const isNotEmptyCheck = (label: string): Validator => {
  return (input: string) => {
    return input.length > 0 ? '' : `${label} is required`;
  };
};

export const validateDecimal=(value:any, decimalLength:number = 2) =>{
  if(value)
  {
    if (!(value.indexOf(".") === -1)){
      // decimal 
      var decimalCount = value.split('.')[1].length;
      if(decimalCount > decimalLength)
        return false;
      else
        return true;
    }
    else{
      return true;
    }
  }
}

//Validate hours & minutes
export const validateHoursMinutes=(value:any, hoursMinutesLength:number = 2) =>{
  if(value)
  {
    if (!(value.indexOf(":") === -1)){
      //Hours
      var hours = value.split(':')[0];
      var hoursCount = value.split(':')[0].length;
      // Minutes 
      var minutes = value.split(':')[1];
      var minutesCount = value.split(':')[1].length;

      var numberHours = Number(hours);
      var numberMinutes = Number(minutes);

      if(!Number.isInteger(numberHours) || !Number.isInteger(numberMinutes) || typeof numberHours !== 'number' || typeof numberMinutes !== 'number' 
              || hours > 23 || minutes > 59 || hoursCount < hoursMinutesLength || minutesCount < hoursMinutesLength)
        return false;
      else
        return true;
    }
    else{
      return false;
    }
  }
}

//Validate start time & end time - Start Time is below End Time
export const validateStartTimeEndTime = (unserviceableDate:any ,standTo: any, standDown:any) => {
  if(unserviceableDate && standTo !== undefined && standTo !== "" && standDown !== undefined && standDown !== "")
  {
      let dtStandTo = new Date(moment(unserviceableDate).format("yyyy-MM-DD") + " " + standTo);
      let dtStandDown = new Date(moment(unserviceableDate).format("yyyy-MM-DD") + " " + standDown);

      if(!isNaN(dtStandTo.getTime()) && !isNaN(dtStandDown.getTime()))
      {
        var startDateTime = moment(dtStandTo).format("yyyy-MM-DD HH:mm:ss");
        var endDateTime = moment(dtStandDown).format("yyyy-MM-DD HH:mm:ss");

        if(startDateTime && endDateTime)
        {
          var startHours = moment(startDateTime).format("HH");
          var endHours = moment(endDateTime).format("HH");

          var startMinutes = moment(startDateTime).format("mm");
          var endMinutes = moment(endDateTime).format("mm");

          if(startHours && endHours && startMinutes && endMinutes)
          {
            if(Number(endHours) > Number(startHours))
            {
              return 1;
            }
            else if(Number(endHours) >= Number(startHours) && Number(endMinutes) > Number(startMinutes))
            {
              return 1;
            }
            else{
              return 0;
            }
          }
          else{
            return 0;
          }
        }
        else{
          return 0;
        }
      }
      else{
        return 0;
      }
    }
    else{
      return 0;
    }
  
}
