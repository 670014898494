import { getDateFormat } from "../../utils/dateHandler";

export interface IUSLog{
  uSLogId:any,
  flightReportId:any,

  startTime:any,
  isStartTimeError?:boolean,
  startTimeError?:string,

  endTime:any,
  isEndTimeError?:boolean,
  endTimeError?:string,

  reason:any,

  deleted:boolean,

  createUserId?:string,
  createTimestamp?:string,
  updateTimestamp?:string,
  updateUserId?: string,   
} 

export class USLog implements IUSLog{
  readonly uSLogId: any;
  flightReportId: any;
  
  readonly startTime: any;
  isStartTimeError?:boolean;
  startTimeError?:string;

  readonly endTime: any;
  isEndTimeError?:boolean;
  endTimeError?:string;

  readonly reason: any;

  deleted: boolean = false;

  createUserId?: string;
  createTimestamp?: string;
  updateTimestamp?: string;
  updateUserId?: string;

    constructor(from?: Partial<IUSLog>) {
      this.uSLogId = from?.uSLogId;
      this.flightReportId = from?.flightReportId ?? "";

      this.startTime = from?.startTime ?? "";
      this.isStartTimeError = from?.isStartTimeError ?? false;
      this.startTimeError = from?.startTimeError ?? "";

      this.endTime = from?.endTime ?? "";
      this.isEndTimeError = from?.isEndTimeError ?? false;
      this.endTimeError = from?.endTimeError ?? "";

      this.reason = from?.reason ?? "";

      this.deleted = from?.deleted ?? false;

      this.createUserId= from?.createUserId ?? "";
      this.createTimestamp= getDateFormat(from?.createTimestamp);
      this.updateTimestamp= getDateFormat(from?.updateTimestamp);
      this.updateUserId= from?.updateUserId ?? "";
        
    }
}





