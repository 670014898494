import { httpFinancial1GXApim } from "../../utils/financialAPIMConfig";
import { IFilterOneGX } from "../../model/1GXCoding/IFilterOneGX";
import { IFund } from "../../model/1GXCoding/IFund";
import { IArrayOption } from "../../model/IArrayOption";
import { IFinancialResult } from "../../model/search/IPaginationResult";

//Search
const getSearch = async (objIFilter: IFilterOneGX, strToken:string = "") => {
  
    const data = await httpFinancial1GXApim.get(
      `GetFundMaster?listType=${objIFilter.listType}&Search=${objIFilter.Search}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
   
    return data;
  };

  
  export const FundService = {
    getSearch,
  };

  export const bindFundDropdown = (fundPaginationResult:IFinancialResult<IFund>): IArrayOption[] => {
    const options = [];

    if(fundPaginationResult.data)
    {
      for (let i = 0; i < fundPaginationResult.data.length; i++) {
        let obj : IArrayOption = {label: fundPaginationResult.data[i].fundName + " - " + fundPaginationResult.data[i].fund, value: fundPaginationResult.data[i].fund};
        options.push(obj);
      }
    }

    return options;
  }

