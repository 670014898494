import { httpFinancial1GXApim } from "../../utils/financialAPIMConfig";
import { IAccount } from "../../model/1GXCoding/IAccount";
import { IFilterOneGX } from "../../model/1GXCoding/IFilterOneGX";
import { IArrayOption } from "../../model/IArrayOption";
import { IFinancialResult } from "../../model/search/IPaginationResult";

//Search
const getSearch = async (objIFilter: IFilterOneGX, strToken:string = "") => {
  
    const data = await httpFinancial1GXApim.get(
      `GetGLAccount?listType=${objIFilter.listType}&Search=${objIFilter.Search}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
   
    return data;
  };

  
  export const AccountService = {
    getSearch,
  };


  export const bindAccountDropdown = (accountPaginationResult:IFinancialResult<IAccount>): IArrayOption[] => {
    const options = [];
    
    if(accountPaginationResult.data)
    {
      for (let i = 0; i < accountPaginationResult.data.length; i++) {
        let obj : IArrayOption = {label: accountPaginationResult.data[i].glAccountLongName + " - " + accountPaginationResult.data[i].glAccount, value: accountPaginationResult.data[i].glAccount};
        options.push(obj);
      }
    }

    return options;
  }
