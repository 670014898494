import * as React from "react";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { IUSLog } from "../../model/Unserviceable/IUSLog";
import { calculateUSLogs } from "../../utils/commonMethods";

interface ITotalUnserviceableHoursColumnProps {
  displayText:string;
  uSLogPaginationResult: IPaginationResult<IUSLog> | undefined;
}

const TotalUnserviceableHoursColumn: React.FunctionComponent<ITotalUnserviceableHoursColumnProps> = ({displayText, uSLogPaginationResult, ...props
}) => {
  return (
    <>
        <td colSpan={1} className="text-right">
          {displayText}
        </td>
        <td colSpan={2} className="fontBold">
          {
            calculateUSLogs(uSLogPaginationResult, 1) 
          }
        </td>
    </>
  );
};

export default TotalUnserviceableHoursColumn;
