import { IInternalOrder } from "../../model/1GXCoding/IInternalOrder";
import { httpFinancial1GXApim } from "../../utils/financialAPIMConfig";
import { IFinancialResult } from "../../model/search/IPaginationResult";
import { IArrayOption } from "../../model/IArrayOption";
import { IFilterOneGX } from "../../model/1GXCoding/IFilterOneGX";

//Search
const getSearch = async (objFilter: IFilterOneGX, strToken:string = "") => {
    const data = await httpFinancial1GXApim.get(
      `GetInternalCode?listType=${objFilter.listType}&Search=${objFilter.Search}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    
  return data;
  };

  export const InternalOrderService = {
    getSearch,
  };

  export const bindInternalOrderDropdown = (internalOrderPaginationResult:IFinancialResult<IInternalOrder>): IArrayOption[] => {
    const options = [];
    const empty: IArrayOption = {label:"--Select--", value:null};
    options.push(empty);

    if(internalOrderPaginationResult.data)
    {
      for (let i = 0; i < internalOrderPaginationResult.data.length; i++) {
        let obj : IArrayOption = {label: internalOrderPaginationResult.data[i].internalOrderDescription + " - " + internalOrderPaginationResult.data[i].internalOrder, value: internalOrderPaginationResult.data[i].internalOrder};
        options.push(obj);
      }
    }

    return options;
  }

