import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IFlightReportDashboard } from "../../model/flightReportDashboard/IFlightReportDashboard";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch,strToken:string = "") => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
      reportDateFrom:objISearch.filterBy?.reportDateFrom,
      reportDateTo:objISearch.filterBy?.reportDateTo,
      corporateRegions:objISearch.filterBy?.corporateRegions,
    },
    paginationInfo: {
      perPage: objISearch.pagination?.perPage,
      page: objISearch.pagination?.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IFlightReportDashboard>>(
    "flight-report-dashboard/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  
  return data;
};

//Search
const getStatusCount = async (objISearch: ISearch,strToken:string = "") => {
  const data = [];

  let obj = {label: "In progress", value: "In progress"};
  data.push(obj);
  obj = {label: "Returned", value: "Returned"};
  data.push(obj);
  obj = {label: "Signed off", value: "Signed off"};
  data.push(obj);
  obj = {label: "Approved", value: "Approved"};
  data.push(obj);
  obj = {label: "Processed", value: "Processed"};
  data.push(obj);
 

  
  return data;
};

export const FlightReportDashboardService = {
  getSearch,
  getStatusCount
};
