import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { USLog } from "../../model/Unserviceable/IUSLog";

const getById = async (id: string, strToken: string = "") => {
  const data = await httpAviationApim.post<USLog>(
    `/unserviceable-log/get/${id}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const create = async (uSLog: USLog, strToken:string = "") => {
  const params = JSON.stringify(uSLog);
 
  const data = await httpAviationApim.post<USLog>(
    `/unserviceable-log/create`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

const update = async (uSLog: USLog, strToken:string = "") => {
  const params = JSON.stringify(uSLog);
 
  //httpAviationApim
  const data = await httpAviationApim.put<USLog>(
    `/unserviceable-log/update/${uSLog.uSLogId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

const createUpdate = async (arrUSLogs:Array<USLog>, flightReportId:number, strToken: string = "") => {
  const params = JSON.stringify(arrUSLogs);
  
  const data = await httpAviationApim.put<Array<USLog>>(
    `/unserviceable-log/create-update/${flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;

};

export const UnserviceableLogService = {
  getById,
  create,
  update,
  createUpdate
};

