
import { IPagination, Pagination } from "./IPagination";

export interface IPaginationResult<T>
  {
    status?:string | undefined,
    errorCodeId:string,
    errorMessage:string,
    paginationInfo:IPagination,
    data: T[]
  };

export class PaginationResult<T> implements IPaginationResult<T>
{
    status?:string | undefined;
    errorCodeId!: string;
    errorMessage!: string;
    paginationInfo!: Pagination;
    data!: T[];
}

export interface IFinancialResult<T>
{
  count?:string | undefined,
  data: T[]
};