import { GoABadge, GoAButton, GoADetails, GoAFormItem, GoAIcon, GoATab, GoATable, GoATabs } from '@abgov/react-components';
import * as React from 'react';
import '../../newLayout.css'

interface INewDesignLayoutHeaderProps {
}

const NewDesignLayoutHeader: React.FunctionComponent<INewDesignLayoutHeaderProps> = (props) => {
    const [isSectionOpen, setIsSectionOpen] = React.useState(true);

    const toggleSection = () => {
        setIsSectionOpen(!isSectionOpen);
    };

  return (
    <>
      <div className="new-layout-main">
        <div className="new-layout-header">
          <div>
            <GoAButton type="tertiary" leadingIcon="arrow-back">
              Back
            </GoAButton>
          </div>
          <div className="new-layout-header-section">
            <div className="form-row">
              <div className="col-50">
                <div className="contentInline detailHeader">
                  <div className="heading left paddingLeft0px">
                    <label>Flight Report 49335</label>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Flight Date">
                      <label>2023/05/02</label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Contract Registration">
                      <label>GLAF</label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Status">
                      <label>
                        <GoABadge type="information" content="In progress" />
                      </label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>

              <div className="col-50">
                <div className="right">
                  <div className="col-15 padding-left-right10">
                    <GoAIcon type="information-circle" />
                  </div>
                  <div className="col-15 padding-left-right10">
                    <GoAButton size="compact" type="tertiary">
                      <GoAIcon type="flag" theme="outline" />
                    </GoAButton>
                  </div>
                  <GoAButton size="compact" type="primary">
                    Sign Off
                  </GoAButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Left section */}
        <div className="new-layout-container">
          <div className="left-section">
            <div
              className={`sliding-section ${isSectionOpen ? "open" : "closed"}`}
            >
              <div className="toggle-button" onClick={toggleSection}>
                {isSectionOpen ? (
                  <GoAIcon type="arrow-back" />
                ) : (
                  <GoAIcon type="arrow-forward" />
                )}
              </div>
              <div className="left-section-content new-layout-content">
                <div>
                  <GoADetails heading="Flight report summary">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <GoAButton type="tertiary" trailingIcon="pencil">
                        Edit
                      </GoAButton>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Date">
                          <label>2023/05/02</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="AO-02 Number">
                          <label>12345</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Contract Registration">
                          <label>GNPU</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Flying Registration">
                          <label>GNPU</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Meter U/S">
                          <label>Yes</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="No Fly Day">
                          <label>No</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Meter Start">
                          <label>-</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Meter Finish">
                          <label>-</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Meter Difference">
                          <label>-</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Forest Area">
                          <label>Edmonton</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Departure Location">
                          <label>Edmonton</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Down For Night Location">
                          <label>Slave Lake</label>
                        </GoAFormItem>
                      </div>
                    </div>
                  </GoADetails>

                  <GoADetails heading="Hiring details">
                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Vendor">
                          <label>Slave lake helicopter</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Vendor ID">
                          <label>123456</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Hiring Start Date">
                          <label>2022/11/11</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Hiring End Date">
                          <label>2022/11/11</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Point Of Hire">
                          <label>Edmonton</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Contract Type">
                          <label>Casual</label>
                        </GoAFormItem>
                      </div>
                      <div className="col-50">
                        <GoAFormItem label="Aircraft Type & Size">
                          <label>AS350 B2 Medium</label>
                        </GoAFormItem>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-50">
                        <GoAFormItem label="Hiring Remarks">
                          <label>
                            Test remarks - Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Nulla mi enim, sodales
                            ac viverra vel, malesuada eu lorem. Ut rhoncus in
                            nisl non dignissim. Quisque diam elit.
                          </label>
                        </GoAFormItem>
                      </div>
                    </div>
                  </GoADetails>
                </div>
              </div>
            </div>
          </div>

          {/* Right section */}
          <div className="right-section right-section-content new-layout-content">
            <GoATabs>
              <GoATab heading="Flying Hours">
                <div className="white-background">
                  <GoATable width="100%">
                    <thead>
                      <tr style={{ position: "sticky", top: 0 }}>
                        <th className="width10">Fire number</th>
                        <th className="width15">Crew type on board</th>
                        <th className="width15">Activity</th>
                        <th className="width10">Rate type</th>
                        <th className="width8">No.of units</th>
                        <th className="width10">Unit</th>
                        <th>Rate per unit</th>
                        <th>Cost</th>
                        <th className="width8">Internal order</th>
                        <th className="width8">Cost centre</th>
                        <th className="width8">Fund</th>
                        <th className="width8">Account</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>HWF001</td>
                        <td>EQUIP161 -Wildland Engine Type 4 w/Crew</td>
                        <td>FS01 Fire Suppression Activities</td>
                        <td>Dry</td>
                        <td>2</td>
                        <td>Hour</td>
                        <td>$2245</td>
                        <td>$4490</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>HWF001</td>
                        <td>EQUIP161 -Wildland Engine Type 4 w/Crew</td>
                        <td>FS01 Fire Suppression Activities</td>
                        <td>Dry</td>
                        <td>2</td>
                        <td>Hour</td>
                        <td>$2245</td>
                        <td>$4490</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </GoATable>
                </div>
              </GoATab>

              <GoATab heading="Expenses">
                <div className="row">
                  <div className="width100">
                    <div className="column width50">
                      <div className="row accordion fuel">
                        <div>
                          <div>
                            <h3 className="insideHeader expense-section-header">
                              Fuel
                            </h3>
                          </div>
                          <div className="content">
                            <div className="width50 col-md-6">
                              <GoAFormItem label="Provider">
                                <label>GOA Provided</label>
                              </GoAFormItem>
                            </div>
                            <div className="width50 col-md-6">
                              <GoAFormItem label="Litres">
                                <label>0</label>
                              </GoAFormItem>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right width50 paddingLeft20px">
                      <div className="row accordion flight-crew">
                        <div>
                          <div>
                            <h3 className="insideHeader expense-section-header">
                              Double Crew
                            </h3>
                          </div>
                          <div className="content">
                            <div className="width100 col-md-6">
                              <GoAFormItem label="">
                                <label>No</label>
                              </GoAFormItem>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row accordion flight-crew-eligibility">
                  <div>
                    <div>
                      <h3 className="insideHeader expense-section-header">
                        Flight Crew Eligibility
                      </h3>
                    </div>
                    <div className="content">
                      <div className="white-background paddingBottom20px">
                        <GoATable width="100%">
                          <thead>
                            <tr style={{ position: "sticky", top: 0 }}>
                              <th className="width15">Name</th>
                              <th className="width15">Role</th>
                              <th className="width15">Breakfast</th>
                              <th className="width15">Lunch</th>
                              <th className="width15">Dinner</th>
                              <th className="width10">Lodging</th>
                              <th className="width10">Vehicle</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </GoATable>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <GoATable width="100%">
                    <thead>
                      <tr style={{ position: "sticky", top: 0 }}>
                        <th className="width15">Fire number</th>
                        <th className="width15">Activity</th>
                        <th className="width15">Rate type</th>
                        <th className="width8">No.of units</th>
                        <th className="width10">Unit</th>
                        <th>Rate per unit</th>
                        <th>Cost</th>
                        <th className="width8">Internal order</th>
                        <th className="width8">Cost center</th>
                        <th className="width8">Fund</th>
                        <th className="width8">Account</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </GoATable>
                </div>
              </GoATab>
              <GoATab heading="Notes"></GoATab>
            </GoATabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDesignLayoutHeader;
