import { GoATable, GoABlock, GoADropdown, GoADropdownItem, GoASpacer, GoAPagination, GoATableSortHeader, GoABadge } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import CacheType from '../../model/enum/CacheType';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { IFlightReportDashboard } from '../../model/flightReportDashboard/IFlightReportDashboard';
import { IArrayOption } from '../../model/IArrayOption';
import { IForestArea } from '../../model/ICorporateRegion';
import { IFlightReport } from '../../model/IFlightReport';
import { CacheLocalStorage } from '../../model/search/ICacheLocalStorage';
import { IFilter } from '../../model/search/IFilter';
import { IPagination } from '../../model/search/IPagination';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { ISearch } from '../../model/search/ISearch';
import { FlightReportDashboardService } from '../../services/flightReportDashboard/FlightReportDashboardService';
import { getCacheStorageItem, onFilterPropertyChange } from '../../utils/cacheMethods';
import { getFilterStatus } from '../../utils/commonMethods';
import FlightReportFlagged from './FlightReportFlagged';

interface IFlightReportAllProps {
    forestAreaSelected:never[],
    startDate:string,
    endDate:string,
    statusCountSelected:IArrayOption[],
    searchValue:string,
    sortColumn:string,
    sortDirection:number,
    perPageCount:number,
    pageNumber:number,
    isRequireRerender:boolean,
    onClickFlightReport:(flightReportId: number)=>void,
    onChangeSortAndPagination:(value: CacheLocalStorage) => void,
}

const FlightReportAll: React.FunctionComponent<IFlightReportAllProps> = ({forestAreaSelected,startDate,endDate,statusCountSelected,searchValue,
    onClickFlightReport, onChangeSortAndPagination, sortColumn, sortDirection, perPageCount, pageNumber, isRequireRerender, ...props}) => {
  const auth = useAuth();
  
  const[tempStatusCountSelected, setTempStatusCountSelected] = React.useState<IArrayOption[]>();

  //Navigation
  const navigate = useNavigate();
  //Data set
  const [paginationResults, setPaginationResult] = React.useState<IPaginationResult<IFlightReportDashboard>>();
  //Loader
  const [loading, setIsLoading] = React.useState(true);
  
  //Pagination
  const [, setPageFlightReports] = React.useState<any>([]);
  // page number
  const [page, setPage] = React.useState(pageNumber);
  //count per page
  const [perPage, setPerPage] = React.useState(perPageCount);

  //Sorting
  const [sortCol, setSortCol] = React.useState(sortColumn);
  const [sortDir, setSortDir] = React.useState(sortDirection);

  const [isChildDataLoaded, setIsDataLoaded] = React.useState(false);

  React.useEffect(() => {
    setTempStatusCountSelected(statusCountSelected);

    if(!isChildDataLoaded || isRequireRerender)
    {
      // console.log("isDataLoaded", isChildDataLoaded)
      // console.log("sortColumn", sortColumn)
      // console.log("sortCol", sortCol)
      // console.log("sortDirection", sortDirection)
      // console.log("sortDir", sortDir)
      // console.log("perPage", perPage)
      // console.log("perPageCount", perPageCount)
      // console.log("page", page)
      // console.log("pageNumber", pageNumber)

      setTimeout(async ()=>{ await onRefreshFlightReport(); },200);
      setIsDataLoaded(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, startDate, endDate, forestAreaSelected, statusCountSelected, searchValue, sortColumn, sortDirection]);

  async function onRefreshFlightReport(){
    await getFlightReports();
    if(paginationResults?.data)
      setPageFlightReports(paginationResults?.data.slice(0, perPage));
  };

  //Get flight reports data
  const getFlightReports = async () => {
    setIsLoading(true);

    if(tempStatusCountSelected && statusCountSelected !== tempStatusCountSelected)
    {
      setPage(1);
    }
    
    let strSearchValue = searchValue.toLowerCase();
    let sortOrder = sortDirection !== sortDir ? sortDirection === -1 ? "desc" : "asc" : sortDir === -1 ? "desc" : "asc";
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
    };

    //Get status based on filter
    var strStatus= getFilterStatus(statusCountSelected);
    
    let objIPagination: IPagination = {
      perPage: perPage,
      page: page,
    };

    let objIFilter: IFilter = {
      columnName: strStatus !== "" ? "status" : "",
      columnValue: strStatus !== "" ? "[" + strStatus + "]" : "",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegions:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: sortCol,
      sortOrder: sortOrder,
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    setTimeout(() => {
      FlightReportDashboardService.getSearch(objISearch, auth.user?.access_token as string)
        .then((response: any) => {
          setPaginationResult(p => {
            return response.data;
            });
            
          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);
          sessionStorage.setItem("api_token", "");
          navigate("/");
        });
      }, 200);
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);

    //setPerPage(paginationResults?.paginationInfo.total || 30);
    //setPage(1);
    setIsDataLoaded(false);

    onFilterPropertyChange(CacheType.aviation_cache, { sortColumn: sortBy, sortDirection: sortDir, pageNumber:1 });
    callToParentOnChangeSortAndPagination();
  }

  //Pagination change page
  function changePage(newPage: any) {
    if(newPage)
    {
      setIsLoading(true);
      const offset = (newPage - 1) * perPage;
      const _flightReports = paginationResults?.data.slice(
        offset,
        offset + perPage
      );

      setPerPage(perPage);
      setPage(newPage);
      setPageFlightReports(_flightReports);
      setIsDataLoaded(false);

      //Apply filter on change of property
      onFilterPropertyChange(CacheType.aviation_cache, { perPageCount: perPage, pageNumber: newPage });
      callToParentOnChangeSortAndPagination();
    }
  }

  function changePerPage(name: any, value: any) {
    if(value)
    {
      setIsLoading(true);
      const newPerPage = parseInt(value, 10);
      const offset = (page - 1) * newPerPage;

      const _flightReports = paginationResults?.data.slice(
        offset,
        offset + newPerPage
      );

      setPageFlightReports(_flightReports);
      //setSearchValue("");
      setPerPage(p => { return newPerPage; });
      setPage(page);
      setIsDataLoaded(false);

      //Apply filter on change of property
      onFilterPropertyChange(CacheType.aviation_cache, { perPageCount: newPerPage, pageNumber: page });
      callToParentOnChangeSortAndPagination();
    }
  }

  function callToParentOnChangeSortAndPagination()
  {
    if(localStorage.getItem(CacheType.aviation_cache))
    {
      const cacheData = getCacheStorageItem(CacheType.aviation_cache);

      if(cacheData){
        var cacheLocalStorage = JSON.parse(cacheData);

        onChangeSortAndPagination(cacheLocalStorage);
      }
    }
  }

//#endregion

function flightReportClick(flightReportId?:number)
{
    if(flightReportId)
    {
      onClickFlightReport(flightReportId);
    }
}

//on Flag property values change
function onFlagStatusChange(objFlightReport:IFlightReport) {
  // if(objFlightReport)
  // {
  //   setIsLoading(true);

  //   if(paginationResults?.data)
  //   {
  //     // 1. Find the flightReport with the provided id
  //     const currentPaginationResultsIndex = paginationResults?.data.findIndex((value) => value.flightReportId === objFlightReport.flightReportId);
  //     // 2. Mark the flightReport as complete
  //     const updatedTodo = {...paginationResults?.data[currentPaginationResultsIndex], isFlagged: objFlightReport.isFlagged};

  //     // 3. Update the flightReport list with the updated flightReport
  //     const newPaginationResults = [
  //       ...paginationResults?.data.slice(0, currentPaginationResultsIndex),
  //       updatedTodo,
  //       ...paginationResults?.data.slice(currentPaginationResultsIndex + 1)
  //     ];
  //     paginationResults.data = newPaginationResults;
  //     setPage(1);
  //     setPaginationResult({...paginationResults});
  //   }

  //   setIsLoading(false);
  // }
}

  return(
    <>
        <PageLoader visible={loading} />

        <div>
        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="isFlagged" direction={sortColumn === "isFlagged" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                  </GoATableSortHeader >
                </th>
                <th><GoATableSortHeader name="corporateRegionName" direction={sortColumn === "corporateRegionName" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                      Forest Area
                    </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="flightReportDate" direction={sortColumn === "flightReportDate" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                    Flight Date
                  </GoATableSortHeader>
                </th>
                <th><GoATableSortHeader name="contractRegistrationName" direction={sortColumn === "contractRegistrationName" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                      Contract Registration
                    </GoATableSortHeader>
                </th>
                <th><GoATableSortHeader name="vendorName" direction={sortColumn === "vendorName" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                      Aircraft Vendor
                    </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="flightReportId" direction={sortColumn === "flightReportId" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                    Flight Report #
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="ao02Number" direction={sortColumn === "ao02Number" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                    AO02 #
                  </GoATableSortHeader>
                </th>
                <th>Total flying hours</th>
                <th>
                  <GoATableSortHeader name="status" direction={sortColumn === "status" ? sortDirection === -1 ? "desc" : "asc" : "none"}>
                    Status
                  </GoATableSortHeader>
                </th>
              </tr>
            </thead>

            <tbody style={{ position: "sticky", top: 0 }} className='table-body'>
              {paginationResults?.data && paginationResults.data.length > 0 ? (
                paginationResults.data.map((record:any, index:any) => (
                  <tr key={record.flightReportId}>
                    <td>
                      <FlightReportFlagged flightReport={record} onFlagChange={onFlagStatusChange} />
                    </td>
                    <td>{record?.corporateRegionName}</td>
                    <td>
                      {moment(record.flightReportDate).format("yyyy-MM-DD")}
                    </td>
                    <td>{record?.contractRegistrationName}</td>
                    <td>{record?.vendorName}</td>
                    {/* <td style={{ padding: "0 1rem 0 1rem" }}></td> */}
                    <td>
                      <span><a href={"/flightReportDetail/"+record.flightReportId} onClick={() => flightReportClick(record?.flightReportId)}>{record.flightReportId}</a></span>
                      {/* <GoAButton
                        {...{ style: '"padding: 0 10px 0 10px;height: 90px;"' }}
                        size="compact"
                        type="tertiary"
                        onClick={() => flightReportClick(record?.flightReportId)}
                      >
                        {record.flightReportId}
                      </GoAButton> */}
                    </td>
                    <td>{record.ao02Number}</td>
                    <td>{record.totalFlyingHours}</td>
                    <td>{record.status === FlightReportStatus.returned ? 
                            <GoABadge type="emergency" content="Returned" />
                          : record.status === FlightReportStatus.signedOff ? 
                          <GoABadge type="information" content="Signed off" /> : 
                          record.status === FlightReportStatus.inProgress ? 
                          <GoABadge type="information" content="In progress" /> :
                          record.status === FlightReportStatus.approved ? 
                          <GoABadge type="information" content="Approved" /> : 
                          record.status === FlightReportStatus.processed ? 
                          <GoABadge type="information" content="Processed" /> : "" }
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="centertext">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </GoATable>
        </div>

        <div className={paginationResults?.data && paginationResults?.data.length > 0 ? "visible pagination" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
              <GoADropdown name="selPerPage" onChange={changePerPage} value={perPage.toString()} width="8ch">
                <GoADropdownItem value="10"></GoADropdownItem>
                <GoADropdownItem value="20"></GoADropdownItem>
                <GoADropdownItem value="30"></GoADropdownItem>
              </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>
                of {paginationResults?.paginationInfo.total} items
              </span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={paginationResults?.paginationInfo.total || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
        </div>
        </div>
    </>
  );
};

export default FlightReportAll;
