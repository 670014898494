import * as React from "react";
import { IFlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { calculateTotalFlyingHours } from "../../services/flyingHours/FlyingHoursService";

interface ITotalFlyingHoursColumnProps {
  displayText:string;
  flyingHoursPaginationResult: IPaginationResult<IFlyingHours> | undefined;
}

const TotalFlyingHoursColumn: React.FunctionComponent<ITotalFlyingHoursColumnProps> = ({displayText, flyingHoursPaginationResult, ...props
}) => {
  return (
    <>
        <td colSpan={2}></td>
        <td colSpan={2} className="text-right">
          {displayText}
        </td>
        <td colSpan={9} className="fontBold">
          {
            calculateTotalFlyingHours(flyingHoursPaginationResult, 1) 
          }
        </td>
    </>
  );
};

export default TotalFlyingHoursColumn;
