import { calculateTotalHours } from "../../utils/commonMethods";
import { getDateFormat } from "../../utils/dateHandler";

export interface IUSStandToStandDown{
  uSStandToStandDownId:any,
  flightReportId:any,

  unserviceableDate:any,
  standTo:any,
  standDown:any,
  totalHours?:any,

  deleted:boolean,

  createUserId?:string,
  createTimestamp?:string,
  updateTimestamp?:string,
  updateUserId?: string,   
} 

export class USStandToStandDown implements IUSStandToStandDown{
  readonly uSStandToStandDownId: any;
  flightReportId: any;
  
  unserviceableDate: any;
  readonly standTo: any;
  readonly standDown: any;
  readonly totalHours: any;

  deleted: boolean = false;

  createUserId?: string;
  createTimestamp?: string;
  updateTimestamp?: string;
  updateUserId?: string;

    constructor(from?: Partial<IUSStandToStandDown>) {
      this.uSStandToStandDownId = from?.uSStandToStandDownId;
      this.flightReportId = from?.flightReportId ?? "";

      this.unserviceableDate = from?.unserviceableDate ?? "";
      this.standTo = from?.standTo ?? "";
      this.standDown = from?.standDown ?? "";
      //this.totalHours = calculateHoursAndMinutes(from?.unserviceableDate, from?.standTo, from?.standDown);
      this.totalHours = calculateTotalHours(from?.unserviceableDate, from?.standTo, from?.standDown);

      this.deleted = from?.deleted ?? false;

      this.createUserId= from?.createUserId ?? "";
      this.createTimestamp= getDateFormat(from?.createTimestamp);
      this.updateTimestamp= getDateFormat(from?.updateTimestamp);
      this.updateUserId= from?.updateUserId ?? "";
    }
}





