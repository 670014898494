import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IFireNumber } from "../../model/domainServices/IFireNumber";
import { IArrayOption } from "../../model/IArrayOption";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
    const params = JSON.stringify({
      search: objISearch.search,
      year:objISearch.year,
      
      paginationInfo: {
        perPage: objISearch.pagination?.perPage,
        page: objISearch.pagination?.page,
      },
    });
  
    const data = await httpAviationApim.post<IPaginationResult<IFireNumber>>(
      "/fire-number/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );

    return data;
  };

  const getById = async (id: string, strToken:string = "") => {
    const data = await httpAviationApim.post<IFireNumber>(
      `/fire-number/getById/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };
  
  export const FireNumberService = {
    getSearch,
    getById,
  };

  export const bindFireNumberDropdown = (fireNumberPaginationResult:IPaginationResult<IFireNumber>): IArrayOption[] => {
    const options = [];
    
    const empty: IArrayOption = {label:"--Select--", fireNumber:"", fireYear:undefined, value:null};
    options.push(empty);

    if(fireNumberPaginationResult.data)
    {
      for (let i = 0; i < fireNumberPaginationResult.data.length; i++) {
        let obj: IArrayOption = {label: fireNumberPaginationResult.data[i].fireNumber + " - " + fireNumberPaginationResult.data[i].year, fireNumber:fireNumberPaginationResult.data[i].fireNumber, fireYear:fireNumberPaginationResult.data[i].year, value: fireNumberPaginationResult.data[i].id};
        options.push(obj);
      }
    }

    return options;
  }