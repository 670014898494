import { IArrayOption } from "../IArrayOption";
import { IForestArea } from "../ICorporateRegion";

export interface ICacheLocalStorage{
    id?: string,
    token?:string,
    expiry?: string,
    forestArea?:IForestArea,
    startDate?:string,
    endDate?:string,
    status?:IArrayOption[],
    search?:string,

    tabIndex?:number,
    sortColumn?:string,
    sortDirection?:number,

    perPageCount?:number,
    pageNumber?:number,
};

export class CacheLocalStorage implements ICacheLocalStorage
{
    id?: string;
    token?:string;
    expiry?: string;
    forestArea?:IForestArea;
    startDate?:string;
    endDate?:string;
    status?:IArrayOption[];
    search?:string;

    tabIndex?:number;
    sortColumn?:string;
    sortDirection?:number;

    perPageCount?:number;
    pageNumber?:number;

    constructor(from?: Partial<ICacheLocalStorage>) {
        this.id = from?.id ?? "";
        this.token = from?.token ?? "";
        this.expiry = from?.expiry ?? "";

        this.forestArea = from?.forestArea ?? undefined;

        this.startDate = from?.startDate ?? "";
        this.endDate = from?.endDate ?? "";

        this.status = from?.status ?? undefined;

        this.search = from?.search ?? "";
        
        this.tabIndex = from?.tabIndex ?? 0;
        
        this.sortColumn = from?.sortColumn ?? "flightReportDate";
        this.sortDirection = from?.sortDirection ?? -1;

        this.perPageCount = from?.perPageCount ?? 10;
        this.pageNumber = from?.pageNumber ?? 1;
    }
}





