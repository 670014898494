import { GoAIcon } from '@abgov/react-components';
import * as React from 'react';
import '../../newLayout.css'

interface INewDesignLayoutProps {
}

const NewDesignLayout: React.FunctionComponent<INewDesignLayoutProps> = (props) => {
    const [isSectionOpen, setIsSectionOpen] = React.useState(true);

    const toggleSection = () => {
        setIsSectionOpen(!isSectionOpen);
    };

  return (
    <>
      <div className='new-layout-main'>
        <div className="new-layout-container">
          <div className="left-section">
            <div
              className={`sliding-section ${isSectionOpen ? "open" : "closed"}`}
            >
              <div className="toggle-button" onClick={toggleSection}>
                {isSectionOpen ? (
                  <GoAIcon type="arrow-back" />
                ) : (
                  <GoAIcon type="arrow-forward" />
                )}
              </div>
              <div className="left-section-content new-layout-content">
                <p>Left section content.</p>
              </div>
            </div>
          </div>
          <div className="right-section right-section-content new-layout-content">
            <p>Right section content.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDesignLayout;
