import moment from "moment";

//Date filter
export const getDateWithMonthOffset = (offset: number) => {
  const d = new Date();
  d.setMonth(d.getMonth() + offset);
  return d;
}

export const addDays = (value: Date, offset: number) => {
  const d = value;
  d.setDate(d.getDate() + offset);

  return d;
}

export const minusDays = (value: Date, offset: number) => {
  const d = value;
  d.setDate(d.getDate() - offset);

  return d;
}

export const getTimeFormat = (value: string): string => {
  if (moment(value).isValid()) {
    var time = moment(value).format("HH:mm");
    return time !== "00:00" ? time : "";
  }
  else {
    return value;
  }
}

export const getDateFormat = (value?: string): string =>
{
  if (!value) return "";

  return moment(value).isValid() ? moment(value).format("yyyy-MM-DD") : value;
}

/**
 * Formats a date string in the format "M/D/YYYY, h:mm:ss A" to a custom format.
 * The resulting formatted string includes the full day name, full month name,
 * day of the month, year, time in 12-hour clock format, and time zone abbreviation (MST).
 * @param inputDateString - The input date string in the format "M/D/YYYY, h:mm:ss A".
 * @returns A formatted date string in the format "dddd, MMMM D, YYYY [at] h:mm:ss A [(MST)]".
 */
export const formatDateString = (inputDateString: string): string => {
  const inputDate = moment(new Date(inputDateString).toLocaleString(), "M/D/YYYY, h:mm:ss A");
  const formattedDateString = inputDate.format("dddd, MMMM D, YYYY [at] h:mm:ss A [(MST)]");
  return formattedDateString;
};


/**
 * Converts a UTC-formatted date string to Mountain Standard Time (MST).
 * @param {string} utcDateString - A UTC-formatted date string.
 * @returns {string} - A string representing the input date and time converted to MST.
 */
export const convertUtcToMst=(utcDateString: string): string=> {
  const utcDate = new Date(utcDateString);

  // Get the UTC time in milliseconds
  const utcTime = utcDate.getTime();

  // Calculate the time in MST (UTC-7)
  const mstTime = utcTime - (7 * 60 * 60 * 1000); // MST is UTC-7

  // Create a new Date object with the MST time
  const mstDate = new Date(mstTime);

  const mstDateString = mstDate.toISOString();
  return mstDateString;
}