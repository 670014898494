import { GoATable } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { IUSLog } from '../../model/Unserviceable/IUSLog';
import TotalUnserviceableHoursColumn from './TotalUnserviceableHoursColumn';

interface IViewUSLogProps {
  uSLogPaginationResult: IPaginationResult<IUSLog> | undefined;
}

const ViewUSLog: React.FunctionComponent<IViewUSLogProps> = ({uSLogPaginationResult,...props}) => {
  return(
    <>
      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width100">
            <div className="row accordion unserviceableReport">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Unserviceable Log</h3>
                </div>
                <div className="content">

                  <div className="unserviceable white-background">
                    <GoATable width='100%'>
                      <thead>
                        <tr style={{ position: "sticky", top: 0 }}>
                          <th className="width145px">Start Time</th>
                          <th className="width230px">End Time</th>
                          <th className="width230px">Reason</th>
                        </tr>
                      </thead>

                      <tbody>
                          {uSLogPaginationResult && uSLogPaginationResult.data !== null ? 
                            uSLogPaginationResult.data.map((record: any, idx: number) => (
                              <tr key={idx}>
                              <td>
                                {record?.startTime && record?.startTime !== "" ? moment(record?.startTime, 'HH:mm:ss').format('HH:mm') : ""}
                              </td>
                              <td>
                                {record?.endTime && record?.endTime !== "" ? moment(record?.endTime, 'HH:mm:ss').format('HH:mm') : ""}
                              </td>
                              <td>
                                {record?.reason}
                              </td>
                            </tr>
                          )) : 
                          <></>
                          }
                        
                          {/* Total unserviceable hours row */}
                          <tr>
                            <TotalUnserviceableHoursColumn displayText={"Total unserviceable hours"} uSLogPaginationResult={uSLogPaginationResult}/>
                          </tr>
                        
                        </tbody>
                    </GoATable>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUSLog;
